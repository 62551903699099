import styled, { keyframes, css } from 'styled-components';

export const Wrapper = styled.div`
  margin-top: -16px;
  margin-bottom: 24px;
`;

export const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;

  & svg {
    height: 14px;
  }
`;

export const StyledText = styled.p`
  font-size: 12px;
  display: flex;
  align-items: center;

  & img {
    height: 14px;
    width: 14px;
    margin-right: 4px;
  }
`;

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const animation = css`
  animation-name: ${rotate};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export const Icon = styled.img<{ translating: boolean }>`
  margin-right: 4px;
  width: 14px;
  opacity: 0.8;

  ${(props) => props.translating && animation};
`;
