import React, { useState, useMemo } from 'react';
import { Tooltip } from 'antd';
import { Flex, Button, SearchInput, Table } from 'components';
import { Category, ComponentType, IComponent } from 'lib/Intefaces/IComponent';
import { useAccess } from 'lib/hooks';
import parseDate from 'lib/helpers/parseDate';
import { sort } from 'lib/helpers/arrays';
import { searchComponents } from 'api/repoTool';

import Component from './Modals/Component';
import Filters from './Filters';
import { Container, AmountFound, Status } from './styled';

const getColumns = (renderAction: (component: IComponent) => JSX.Element) => [
  {
    title: 'BundleID',
    key: 'bundleId',
    render: ({ bundleId }: IComponent) => <span>{bundleId}</span>,
    sorter: (a: IComponent, b: IComponent) => sort(a.bundleId, b.bundleId),
  },
  {
    title: 'Component',
    key: 'name',
    render: ({ name }: IComponent) => <Tooltip title={name}>{name}</Tooltip>,
    sorter: (a: IComponent, b: IComponent) => sort(a.name, b.name),
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: 'Category',
    key: 'category',
    render: ({ category }: IComponent) => <Tooltip title={Category[category]}>{Category[category]}</Tooltip>,
    sorter: (a: IComponent, b: IComponent) => sort(a.category, b.category),
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: 'Services',
    key: 'services',
    render: ({ services }: IComponent) => <span>{services.length}</span>,
    sorter: (a: IComponent, b: IComponent) => a.services.length - b.services.length,
  },
  {
    title: 'Last scanned',
    key: 'lastScanned',
    render: ({ lastScanned }: IComponent) => <Tooltip title={parseDate(lastScanned)}>{parseDate(lastScanned)}</Tooltip>,
    sorter: (a: IComponent, b: IComponent) => new Date(a.lastScanned).getTime() - new Date(b.lastScanned).getTime(),
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: 'Status',
    key: 'status',
    render: ({ status }: IComponent) => <Status status={status}>{status ? 'Mapped' : 'Unmapped'}</Status>,
    sorter: (a: IComponent, b: IComponent) => (a.status === b.status ? 0 : a.status ? -1 : 1),
  },
  {
    title: 'Action',
    key: 'action',
    render: (component: IComponent) => renderAction(component),
  },
];

type TFilters = {
  categories: Category[];
  status: string[];
  types: ComponentType[];
};

const filterComponents = (components: IComponent[], filters: TFilters) => {
  const { categories, status, types } = filters;
  const newComponents = components
    .filter((c) => categories.includes(c.category.toUpperCase() as Category))
    .filter((c) => types.includes(c.componentType?.toUpperCase() as ComponentType))
    .filter((c) => (status.includes('mapped') && c.status) || (status.includes('unmapped') && !c.status));
  return newComponents;
};

const RepositoryTool = () => {
  const [value, setValue] = useState('');
  const { isAdmin, isReadAccess } = useAccess();
  const [components, setComponents] = useState<IComponent[]>([]);
  const [selectedComponent, setSelectedComponent] = useState<string | null>(null);
  const [filters, setFilters] = useState<TFilters>({
    categories: [],
    status: [],
    types: [],
  });
  const [searching, setSearching] = useState(false);

  const onFiltersChange = (categories: Category[], status: string[], types: ComponentType[]) => {
    setFilters({ categories, status, types });
  };

  const onUpdate = (component: IComponent) => {
    setComponents(components.map((c) => (c.bundleId === component.bundleId ? component : c)));
  };

  const filteredComponents = useMemo(() => filterComponents(components, filters), [components, filters]);

  const onSearch = async (searchTerm: string) => {
    setSearching(true);
    const results = await searchComponents(searchTerm);
    setComponents(results);
    setSearching(false);
  };

  return (
    <Container>
      <Component
        componentId={selectedComponent}
        onClose={() => setSelectedComponent(null)}
        open={Boolean(selectedComponent)}
        onUpdate={onUpdate}
      />
      <Flex style={{ marginBottom: '24px' }}>
        <SearchInput
          value={value}
          onChange={setValue}
          onSearch={onSearch}
          loading={searching}
          placeholder="Search for component"
          style={{ flex: 1 }}
        />
        <Filters onChange={onFiltersChange} />
        <Button disabled={isReadAccess} onClick={() => setSelectedComponent('create')}>
          Add Component
        </Button>
      </Flex>
      <Table
        autoHeight
        loading={searching}
        columns={getColumns((component) => (
          <span>
            <Button
              styleType="inline"
              onClick={() => setSelectedComponent(component.bundleId)}
              style={{ marginRight: '20px' }}
            >
              {isReadAccess ? 'View' : 'Edit'}
            </Button>
            {isAdmin && (
              <Button styleType="inline" onClick={() => console.log('Trigger scan for component ', component.name)}>
                Scan
              </Button>
            )}
          </span>
        ))}
        data={filteredComponents?.map((component: any) => ({ key: component.bundleId, ...component }))}
      />
      {Boolean(filteredComponents.length) && <AmountFound>{filteredComponents.length} Components found</AmountFound>}
    </Container>
  );
};

export default RepositoryTool;
