import React, { Dispatch, SetStateAction, useState } from 'react';
import { FormInstance } from 'antd';
import { Input, Item, Select, Option, AutotranslateButton } from 'components';
import { inputs, notLanguageSpecificFields } from 'lib/predefinedValues/constants';
import { useLazyQuery } from '@apollo/client';
import {
  dataCollected,
  dataPurposes,
  dataTransferCountries,
  legalBasis,
  technologyUsed,
} from '@usercentrics/template-app-translations';
import { useAccess } from 'lib/hooks';

import ITemplate from 'lib/Intefaces/ITemplate';

import defaultCategorySlug from 'lib/predefinedValues/defaultCategorySlug.json';
import GET_TRANSLATIONS from 'lib/gql/queries/getTranslations';
import TextArea from 'antd/es/input/TextArea';

type ServiceInformationProps = {
  selectedLanguage: string;
  existingLanguages?: Array<string>;
  editTemplate?: boolean;
  form: FormInstance<any>;
  setTemplates?: Dispatch<SetStateAction<ITemplate[]>>;
};

type InputTypes = {
  type: string;
  name: string;
  items?: string;
  label: string;
  required?: boolean;
};

const fields = {
  defaultCategorySlug,
  legalBasis,
  technologyUsed,
  dataTransferCountries,
  dataCollected,
  dataPurposes,
};

const ALLOW_AUTOTRANSLATION = ['descriptionOfService', 'dataRecipientsList', 'retentionPeriodDescription'];

const formatTagsOptions = (tags: { [key: string]: string }) =>
  Object.keys(tags).map((tag) => ({ label: tags[tag], value: tag }));

const ServiceInformation = ({
  form,
  setTemplates,
  selectedLanguage,
  editTemplate,
  existingLanguages,
}: ServiceInformationProps) => {
  const [fieldToBeTranslated, setFieldToBeTranslated] = useState<string | null>(null);
  const [translating, setTranslating] = useState(false);
  const [translated, switchTranslated] = useState<string | null>(null);
  const { isReadAccess } = useAccess();

  const [loadData] = useLazyQuery(GET_TRANSLATIONS, {
    onCompleted: (data) => {
      const translations = data.getTranslation;
      if (setTemplates) {
        setTemplates((ts) =>
          ts.reduce(
            (acc: Array<ITemplate>, curr: ITemplate) => [
              ...acc,
              { ...curr, [fieldToBeTranslated as string]: translations[curr.language] || translations.en },
            ],
            [],
          ),
        );
      }
      setTranslating(false);
      setFieldToBeTranslated(null);
      switchTranslated(fieldToBeTranslated);
    },
  });

  const autotranslate = (field: string) => {
    const value = form.getFieldValue(field);
    setFieldToBeTranslated(field);
    setTranslating(true);
    loadData({
      variables: {
        translationFor: Array.isArray(value) ? value[0] : value,
        sourceLanguage: 'en',
        targetLanguages: existingLanguages,
      },
    });
  };

  return (
    <>
      {inputs.map((input: InputTypes) => {
        if (input.type === 'tags') {
          return (
            <Item
              key={`tags-${input.name}`}
              label={input.label}
              colon={false}
              name={input.name}
              rules={[{ required: input.required, message: `${input.label} should not be empty` }]}
            >
              <Select
                mode="multiple"
                placeholder={`Select ${input.label}`}
                options={formatTagsOptions(input.items && Object(fields)[input.items][selectedLanguage || 'en'])}
                allowClear
                disabled={isReadAccess}
              />
            </Item>
          );
        }
        if (input.type === 'dropdown') {
          return (
            <Item
              key={`dropdown-${input.name}`}
              label={input.label}
              colon={false}
              name={input.name}
              rules={[{ required: input.required, message: `${input.label} should not be empty` }]}
            >
              <Select
                disabled={(editTemplate && selectedLanguage !== 'en') || isReadAccess}
                placeholder={`Select ${input.label}`}
              >
                {input.items &&
                  Object(fields)[input.items].map((item: { key: string; en: string }) => (
                    <Option key={item.key} value={item.key}>
                      {(item as { [key: string]: string })[selectedLanguage || 'en']}
                    </Option>
                  ))}
              </Select>
            </Item>
          );
        }

        if (editTemplate && input.name === 'dataProcessor') return null;

        return (
          <React.Fragment key={input.name}>
            <Item
              key={`item-${input.name}`}
              label={input.label}
              colon={false}
              name={input.name}
              rules={[{ required: input.required, message: `${input.label} should not be empty` }]}
            >
              {input.type === 'textfield' ? (
                <TextArea
                  placeholder={`Enter ${input.label}`}
                  style={{ minHeight: '100px', maxHeight: '140px' }}
                  disabled={
                    isReadAccess ||
                    (editTemplate &&
                      ((selectedLanguage !== 'en' && notLanguageSpecificFields.includes(input.name)) ||
                        input.name === 'dataProcessor'))
                  }
                />
              ) : (
                <Input
                  placeholder={`Enter ${input.label}`}
                  disabled={
                    isReadAccess ||
                    (editTemplate &&
                      ((selectedLanguage !== 'en' && notLanguageSpecificFields.includes(input.name)) ||
                        input.name === 'dataProcessor'))
                  }
                />
              )}
            </Item>
            {Boolean(
              selectedLanguage === 'en' && ALLOW_AUTOTRANSLATION.includes(input.name) && editTemplate && !isReadAccess,
            ) && (
              <AutotranslateButton
                key={`autotranslate-${input.name}`}
                translated={input.name === translated}
                translating={translating}
                onClick={() => autotranslate(input.name)}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default ServiceInformation;
