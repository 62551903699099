import styled from 'styled-components';

export const ReverseRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const DeleteButton = styled.button`
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 24px;
  border: none;
  background-color: ${(props) => props.theme.colors.warning};
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
`;

export const AddStorageInformationButton = styled.button`
  width: 100%;
  padding: 14px;
  border: 1px dashed ${(props) => props.theme.colors.grayBorder};
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  color: ${(props) => props.theme.colors.black};
  font-size: 14px;
  font-weight: 500;

  & span {
    margin-right: 8px;
    font-size: 16px;
    margin-bottom: 0;
  }
`;
