import styled from 'styled-components';
import { Tabs } from '../../../../components';

export const MetadataInfo = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.gray};
  padding: 16px 24px 0 24px;
`;

export const TemplateName = styled.p<TemplateNameInterface>`
  font-size: ${(props) => (props.bold ? '14px' : '12px')};
  font-weight: ${(props) => (props.bold ? '500' : '400')};
  margin-bottom: ${(props) => (props.bold ? '4px' : '24px')};
  color: ${(props) => props.theme.colors.dark};
`;

export const TitleWrapper = styled.div`
  display: flex;

  & div {
    padding: 5px 0;
  }
`;

interface TemplateNameInterface {
  bold?: boolean;
}

interface FormWrapperInterface {
  editTemplate?: boolean;
}

export const WiderTabs = styled(Tabs)`
  display: flex;
  justify-content: center;

  & button {
    width: calc((720px - 40px) / ${(props) => props.tabs.length});
  }
`;

export const Content = styled.div`
  padding: 20px;
  background: ${(props) => props.theme.colors.lightGrayBackground};
`;

export const FormWrapper = styled.div<FormWrapperInterface>`
  height: calc(100% - ${(props) => (props.editTemplate ? '173px' : '109px')});
  display: flex;
  flex-direction: column;
`;
