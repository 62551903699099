import React from 'react';
import styled, { css } from 'styled-components';
import { Tooltip } from 'antd';

const smallStyles = css`
  padding: 0;
  min-width: 20px;
  min-height: 20px;
  text-align: center;
  line-height: 20px;
`;

const Container = styled.label<{ small?: boolean }>`
  border-radius: 4px;
  padding: 5px 8px;
  font-size: 12px;
  font-weight: 600;
  height: 100%;
  color: #f25800;
  background-color: #fef2eb;
  ${(props) => props.small && smallStyles};
`;

const DeprecatedLabel = ({ small, ...props }: { small?: boolean; style?: any }) => {
  if (!small) return <Container {...props}>Deprecated DPS</Container>;

  return (
    <Tooltip title="This DPS is Deprecated">
      <Container {...props}>D</Container>
    </Tooltip>
  );
};

export default DeprecatedLabel;
