import React, { useState, useRef } from 'react';
import { CheckboxProps, Divider } from 'antd';
import fullLanguageNameFromISO from 'lib/helpers/fullLanguageNameFromISO';
import { useAccess, useOnClickOutside } from 'lib/hooks';
import AddIcon from 'assets/icons/Add.svg';
import { CheckboxGroup, Checkbox, Button } from 'components';
import { AddNewLanguagePopup, ButtonWrapper, CheckboxWrapper } from './styled';

type AddNewLanguagesProps = {
  languagesThatCanBeAdded: Array<{ en: string; key: string }>;
  addMultipleLanguages: (languages: Array<string>) => void;
};

const AddNewLanguages = ({ languagesThatCanBeAdded, addMultipleLanguages }: AddNewLanguagesProps) => {
  const container = useRef(null);
  const { isAdmin } = useAccess();
  const [showAddLanguages, toggleAddLanguages] = useState<boolean>(false);
  const [languagesToAdd, setLanguagesToAdd] = useState<string[]>([]);

  useOnClickOutside(container, () => toggleAddLanguages(false));

  if (!isAdmin) return null;

  const checkAll = languagesToAdd.length === languagesThatCanBeAdded.length;
  const indeterminate = languagesToAdd.length > 0 && languagesToAdd.length < languagesThatCanBeAdded.length;

  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    setLanguagesToAdd(e.target.checked ? languagesThatCanBeAdded.map((lang) => lang.key) : []);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Button
        styleType="secondary"
        onClick={() => toggleAddLanguages(!showAddLanguages)}
        style={{ borderRadius: '4px 0 0 4px', borderRight: 'none', padding: '0 10px' }}
      >
        <img src={AddIcon} alt="Add language icon" />
      </Button>
      {showAddLanguages && (
        <AddNewLanguagePopup ref={container}>
          <CheckboxWrapper>
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
              Select all
            </Checkbox>
            <Divider style={{ margin: '8px 0' }} />
            <CheckboxGroup
              options={languagesThatCanBeAdded.map((lang) => ({
                label: fullLanguageNameFromISO(lang.en, lang.key),
                value: lang.key,
              }))}
              defaultValue={[]}
              onChange={(values) => setLanguagesToAdd(values as string[])}
              value={languagesToAdd}
            />
          </CheckboxWrapper>
          <ButtonWrapper>
            <Button
              onClick={() => {
                addMultipleLanguages(languagesToAdd);
                setLanguagesToAdd([]);
              }}
              style={{ width: '100%' }}
              size="small"
            >
              Add Languages
            </Button>
          </ButtonWrapper>
        </AddNewLanguagePopup>
      )}
    </div>
  );
};

export default AddNewLanguages;
