import React, { useState } from 'react';
import ITemplate from 'lib/Intefaces/ITemplate';
import { Button, DeprecatedLabel, Flex, Modal } from 'components';
import { useAccess } from 'lib/hooks';
import { MetadataInfo, TemplateName, TitleWrapper, WiderTabs, Content, FormWrapper } from './styled';
import { Form } from 'antd';
import { setInitialValuesForEdit } from 'lib/predefinedValues/validation';
import Requests from './Tabs/Requests/Requests';

type ModalProps = {
  open: boolean;
  onClose: () => void;
  template: ITemplate;
};

// enum TabsEnum {
//   REQUESTS = 'REQUESTS',
// }

const MetadataModal = ({ open, onClose, template }: ModalProps) => {
  const [showSaveConfirmation, setShowSaveConfirmation] = useState<boolean>(false);
  const { isReadAccess } = useAccess();
  //const [tab, setTab] = useState<TabsEnum>(TabsEnum.REQUESTS);
  const [form] = Form.useForm();

  // When there are changes in the form we move them to templates state as well
  const onFieldsChange = (change: any) => {
    // const field = Object.keys(change)[0];
    // const value = change[field];
    // // Predefined fields that are not language specific, like data purposes, legal basis, etc.
    // // We carry over the values to other languages
    // if (Array.isArray(value) && (Array.isArray(value) || notLanguageSpecificFields.includes(field))) {
    //   setTemplates((tmplts) => tmplts.map((t) => ({ ...t, [field]: value })));
    // }
    // const values = form.getFieldsValue(true);
    // setTemplates((tmplts) => tmplts.map((t) => (t.language === values.language ? values : t)));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={isReadAccess ? 'View Metadata' : 'Edit Metadata'}
      width="600px"
      footer={
        <Flex justifyContent="space-between" alignItems="center">
          <Button styleType="secondary" onClick={onClose}>
            Cancel
          </Button>
          {!isReadAccess && (
            <Button styleType="primary" onClick={() => setShowSaveConfirmation(true)}>
              Save
            </Button>
          )}
        </Flex>
      }
    >
      <MetadataInfo>
        <TitleWrapper>
          <div>
            <TemplateName bold>{template.dataProcessor}</TemplateName>
            <TemplateName>{template.templateId}</TemplateName>
          </div>
          {template.isDeprecated && <DeprecatedLabel style={{ marginLeft: '12px' }} />}
        </TitleWrapper>
        {/*<WiderTabs*/}
        {/*  value={tab}*/}
        {/*  onChange={(value) => setTab(value as TabsEnum)}*/}
        {/*  tabs={[{ label: 'Requests', value: TabsEnum.REQUESTS }]}*/}
        {/*/>*/}
      </MetadataInfo>
      <Content style={{ marginBottom: '46px' }}>
        <FormWrapper editTemplate>
          <Form
            form={form}
            layout="vertical"
            onValuesChange={onFieldsChange}
            initialValues={setInitialValuesForEdit([template])[0]}
          >
            {/*{tab === TabsEnum.REQUESTS && <Requests form={form} isReadOnly={isReadAccess} />}*/}
            <Requests form={form} isReadOnly={isReadAccess} />
          </Form>
        </FormWrapper>
      </Content>
    </Modal>
  );
};

export default MetadataModal;
