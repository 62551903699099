import React from 'react';
import { Switch as AntSwitch, SwitchProps } from 'antd';
import styled from 'styled-components';

const SSwitch = styled(AntSwitch)`
  min-width: 40px;
  height: 20px;

  & .ant-switch-handle {
    height: 16px;
    width: 16px;
  }

  &.ant-switch-checked {
    background: ${(props) => props.theme.colors.primary};

    &:hover {
      background: #013c8e !important;
    }

    & .ant-switch-handle {
      inset-inline-start: calc(100% - 18px);
    }
  }

  &:active {
    & .ant-switch-handle {
      width: 16px;
    }
  }
`;

const Switch = (props: SwitchProps) => <SSwitch {...props} />;

export default Switch;
