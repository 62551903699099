import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Logo from 'assets/UCLogo.svg';
import LogOut from 'assets/icons/logout.svg';
import UserPlaceHolder from 'assets/icons/UserPlaceholder.svg';
import PersonIcon from 'assets/icons/Person.svg';

import { HeaderWrapper, LogOutButton, FlexWrapper, AccessLabel } from './styled';

type HeaderProps = {
  isReadAccess: boolean;
  logout: () => void;
};

const Header = ({ logout, isReadAccess }: HeaderProps) => {
  const { user } = useAuth0();

  return (
    <HeaderWrapper>
      <img src={Logo} alt="usercentrics" />
      <FlexWrapper>
        {isReadAccess && (
          <AccessLabel>
            <img src={PersonIcon} alt="Person icon" />
            READ-ONLY ACCESS
          </AccessLabel>
        )}
        <LogOutButton onClick={() => logout()}>
          <img src={UserPlaceHolder} alt="LogOut" />
          {user ? user.email : 'user'}
          <img src={LogOut} alt="LogOut" />
        </LogOutButton>
      </FlexWrapper>
    </HeaderWrapper>
  );
};

export default Header;
