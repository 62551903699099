import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import { Form } from 'antd';
import { useMutation, useLazyQuery } from '@apollo/client';
import { inputValues } from 'lib/predefinedValues/constants';
import NEW_TEMPLATE from 'lib/gql/mutations/newTemplate';
import { useNotification } from 'lib/hooks';
import { convertToSeconds } from 'lib/helpers/convert-duration/convertDuration';
import GET_CONSENT_TEMPLATE from 'lib/gql/queries/getConsentTemplate';
import ITemplate from 'lib/Intefaces/ITemplate';
import { Modal, Flex, Button } from 'components';
import { deepMerge } from 'lib/helpers/objects';

import ServiceInformation from './Tabs/ServiceInformation';
import StorageInformation from './Tabs/StorageInformation';

import { WiderTabs, Content, FormWrapper } from './styled';

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

enum TabsEnum {
  SERVICE_INFO = 'SERVICE_INFO',
  STORAGE_INFO = 'STORAGE_INFO',
}

const NewTemplate = ({ open, onClose }: ModalProps) => {
  const [form] = Form.useForm();
  const [tab, setTab] = useState<TabsEnum>(TabsEnum.SERVICE_INFO);
  const [createConsentTemplate] = useMutation(NEW_TEMPLATE);
  const notification = useNotification();

  const [submittingTemplate, setSubmittingTemplate] = useState<typeof inputValues>(inputValues);
  const [isSubmitting, setSubmitting] = useState(false);

  const validateDataProcessorName = (data: Array<ITemplate>) => {
    if (!Array.isArray(data)) {
      notification.notify({
        notificationMessage: 'There was an API error, please try again',
        type: 'error',
      });
      setSubmitting(false);
      return;
    }

    const isNameExisting = data
      .map((template) => template.dataProcessor.toLowerCase())
      .includes(submittingTemplate.dataProcessor.toLowerCase());

    if (isNameExisting) {
      notification.notify({
        notificationMessage: 'Name of Service already exists',
        type: 'warning',
      });
      setSubmitting(false);
    } else {
      createConsentTemplate({
        variables: {
          ...submittingTemplate,
          thirdCountryTransfer: submittingTemplate.thirdCountryTransfer.join(','),
          locationOfProcessing: submittingTemplate.locationOfProcessing.join(','),
          cookieMaxAgeSeconds: submittingTemplate.cookieMaxAgeSeconds
            ? convertToSeconds(submittingTemplate.cookieMaxAgeSeconds)
            : null,
          deviceStorage: {
            disclosures: submittingTemplate.deviceStorage.disclosures.map((disclosure: any) => {
              const { newStorage, id, ...disc } = disclosure;
              return {
                ...disc,
                maxAgeSeconds: convertToSeconds(disc.maxAgeSeconds),
                type: disc.type.toLowerCase(),
              };
            }),
          },
        },
      })
        .then(() => {
          setSubmitting(false);
          onClose();
        })
        .catch((error) => {
          Sentry.captureException(error);
          notification.notify({ notificationMessage: error.message, type: 'error' });
          setSubmitting(false);
        });
    }
  };

  const [loadData] = useLazyQuery(GET_CONSENT_TEMPLATE, {
    // After we search for existing data processor name /
    // if such name doesn't exist we can create a new DPS
    onCompleted: (data) => validateDataProcessorName(data.getConsentTemplateByDataProcessorOrTemplateId),
  });

  const onSubmit = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue(true);
      setSubmitting(true);
      setSubmittingTemplate(deepMerge(inputValues, values) as typeof inputValues);
      // Search for existing data processor name
      loadData({ variables: { searchTerm: values.dataProcessor } });
    } catch (e) {
      throw new Error('Validation failed');
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Create new Data Processing Template"
      width="640px"
      footer={
        <Flex justifyContent="space-between" alignItems="center">
          <Button styleType="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button styleType="primary" onClick={onSubmit} loading={isSubmitting}>
            Create Template
          </Button>
        </Flex>
      }
    >
      <WiderTabs
        value={tab}
        onChange={(value) => setTab(value as TabsEnum)}
        tabs={[
          { label: 'Service Information', value: TabsEnum.SERVICE_INFO },
          { label: 'Storage Information', value: TabsEnum.STORAGE_INFO },
        ]}
        style={{ marginTop: '24px' }}
      />
      <Content>
        <FormWrapper>
          <Form form={form} layout="vertical" initialValues={inputValues}>
            {tab === TabsEnum.SERVICE_INFO && <ServiceInformation selectedLanguage="en" form={form} />}
            {tab === TabsEnum.STORAGE_INFO && <StorageInformation selectedLanguage="en" form={form} />}
          </Form>
        </FormWrapper>
      </Content>
    </Modal>
  );
};

export default NewTemplate;
