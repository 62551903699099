import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';
import theme from 'lib/theme';
import { useAuthenticated } from 'lib/hooks';
import AccessProvider from 'lib/context/Access';
import Spin from 'components/Spin/Spin';

// Pages
import Main from './Main';
import Login from './Login';

const App = () => {
  const { isLoading, client } = useAuthenticated();
  const { logout } = useAuth0();

  const onLogout = () => {
    logout({
      returnTo: window.location.port
        ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}/login`
        : `${window.location.protocol}//${window.location.hostname}/login`,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        {isLoading ? (
          <Spin />
        ) : (
          <AccessProvider logout={onLogout}>
            <Switch>
              <Route path="/" exact render={() => <Main logout={onLogout} />} />
              <Route path="/login" component={Login} />
            </Switch>
          </AccessProvider>
        )}
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default App;
