type PlainObject = { [key: string]: any };

const isObject = (value: any): value is PlainObject =>
  typeof value === 'object' && value !== null && !Array.isArray(value);

export const deepMerge = (target: PlainObject, ...sources: PlainObject[]): PlainObject => {
  // Create a clone of the target object to avoid modifying the original object
  const result = { ...target };

  // Iterate over each source object
  sources.forEach((source) => {
    Object.keys(source).forEach((key) => {
      // Check if the value is an object and not an array or null
      if (isObject(source[key]) && !Array.isArray(source[key]) && source[key] !== null) {
        // If the key doesn't exist in the result or isn't an object, initialize it as an empty object
        if (!result[key] || typeof result[key] !== 'object' || Array.isArray(result[key])) {
          result[key] = {};
        }
        // Recursively merge the object
        result[key] = deepMerge(result[key], source[key]);
      } else {
        // Directly assign the value for non-object types
        result[key] = source[key];
      }
    });
  });

  return result;
};
