import { Select as AntdSelect } from 'antd';
import styled from 'styled-components';

export const Select = styled(AntdSelect)`
  min-height: 40px;

  && .ant-select-selector {
    border-color: ${(props) => props.theme.colors.border};
    border-radius: 4px;
    min-height: 40px;
    align-items: center;
  }

  & .ant-select-selection-item {
    font-size: 14px;
  }

  & .ant-select-selection-overflow-item {
    width: auto;
  }

  & .ant-select-selection-placeholder {
    font-size: 14px;
    font-weight: 400;
  }
`;

export const Option = styled(Select.Option)`
  & .ant-select-item-option-selected {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    background-color: ${(props) => props.theme.colors.selectOptionBackground};
  }
`;
