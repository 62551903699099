import styled from 'styled-components';
import { Tabs } from 'components';

interface FormWrapperInterface {
  editTemplate?: boolean;
}

export const FormWrapper = styled.div<FormWrapperInterface>`
  height: calc(100% - ${(props) => (props.editTemplate ? '173px' : '109px')});
  display: flex;
  flex-direction: column;
`;

interface ButtonInterface {
  primary?: boolean;
  marginLeft?: boolean;
}

export const Button = styled.button<ButtonInterface>`
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 24px;
  border: none;
  background-color: ${(props) => (props.primary ? props.theme.colors.primary : 'transparent')};
  color: ${(props) => (props.primary ? props.theme.colors.white : props.theme.colors.dark)};
  ${(props) => props.marginLeft && 'margin-left: 16px;'}
  cursor: pointer;

  &:disabled {
    opacity: 0.75;
    cursor: default;
  }
`;

export const TemplateInfo = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.gray};
  padding: 16px 24px 0 24px;
`;

interface TemplateNameInterface {
  bold?: boolean;
}

export const TemplateName = styled.p<TemplateNameInterface>`
  font-size: ${(props) => (props.bold ? '14px' : '12px')};
  font-weight: ${(props) => (props.bold ? '500' : '400')};
  margin-bottom: ${(props) => (props.bold ? '4px' : '24px')};
  color: ${(props) => props.theme.colors.dark};
`;

export const AutoTranslate = styled.label`
  width: 100%;
  margin-bottom: 20px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.dark};
  display: flex;
  align-items: center;

  & svg {
    margin-left: 8px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;

  & div {
    padding: 5px 0;
  }
`;

export const LanguageSelector = styled.select`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray};
  border-radius: 2;
`;

export const LanguagesWrapper = styled.div`
  display: flex;
`;

export const WiderTabs = styled(Tabs)`
  display: flex;
  justify-content: center;

  & button {
    width: calc((640px - 40px) / ${(props) => props.tabs.length});
  }
`;

export const Content = styled.div`
  padding: 20px;
  background: ${(props) => props.theme.colors.lightGrayBackground};
`;
