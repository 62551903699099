import { LANGUAGES } from '@usercentrics/template-app-translations';
import fullLanguageNameFromISO from 'lib/helpers/fullLanguageNameFromISO';

export default function useSupportedLanguages(existingLanguages: Array<string>) {
  const isLanguageExists = (value: string) => existingLanguages.includes(value);

  const parseLanguages = (languages: Array<string>) =>
    languages
      .map((value: string) => ({
        key: value,
        value: fullLanguageNameFromISO(value, value), // full english value (for dropdowns)
        en: value,
        isKeepOpenAfterClick: !isLanguageExists(value),
      }))
      .sort((la, lb) => la.key.localeCompare(lb.key));

  return {
    supportedLanguages: parseLanguages(LANGUAGES),
    languagesThatCanBeAdded: parseLanguages(LANGUAGES).filter((lang) => !isLanguageExists(lang.key)),
    templateLanguages: parseLanguages(existingLanguages),
  };
}
