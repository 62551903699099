import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import GET_ADMINS from 'lib/gql/queries/getAdmins';
import { useNotification } from 'lib/hooks';
import { USERS_ALLOWED_TO_USE_WIX_REPO } from 'lib/consts';

export const AccessContext = createContext({
  isReadAccess: false,
  isAdmin: false,
});

const AccessProvider = ({ logout, children }: { logout: () => void; children: ReactNode | ReactNode[] }) => {
  const [isAdmin, setAdmin] = useState(false);
  const [isReadAccess, setReadAccess] = useState(false);
  const notification = useNotification();
  const { isAuthenticated, user } = useAuth0();

  const [loadAdmins, { error: loadAdminsError }] = useLazyQuery(GET_ADMINS, {
    onCompleted: (response) => {
      if (loadAdminsError || response.superAdminData === null) {
        if (isAuthenticated) {
          logout();
        }
      } else if (
        response.superAdminData?.appAccess?.includes('consentTemplates') ||
        USERS_ALLOWED_TO_USE_WIX_REPO.includes(user?.email || '')
      ) {
        setAdmin(true);
      } else if (response.superAdminData?.email.endsWith('@usercentrics.com')) {
        setReadAccess(true);
      } else {
        notification.notify({ notificationMessage: 'Access denied', type: 'warning' });
        setAdmin(false);
      }
    },
  });

  useEffect(() => {
    if (isAuthenticated) {
      loadAdmins();
    }
  }, [isAuthenticated]);

  return <AccessContext.Provider value={{ isReadAccess, isAdmin }}>{children}</AccessContext.Provider>;
};

export default AccessProvider;
