import { Input as AntdInput } from 'antd';
import styled from 'styled-components';

export const Input = styled(AntdInput)`
  height: 40px;
  border-radius: 4px;
  width: 100%;
  color: ${(props) => props.theme.colors.primaryText};

  &.ant-input-status-error {
    border-color: ${(props) => props.theme.colors.warning} !important;
  }

  & textarea {
    border-radius: 4px;
  }

  & .ant-input-group {
    height: 40px;

    & .ant-input-group-addon {
      background: ${(props) => props.theme.colors.inputBackground};
      color: ${(props) => props.theme.colors.gray};
    }

    & .ant-input {
      height: 100%;
    }
  }
`;

export const TextArea = styled(AntdInput.TextArea)`
  border-radius: 4px;
`;
