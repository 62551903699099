import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';

interface IProps {
  onChange: (value: string) => void;
  value: string;
  tabs: { label: string; value: string; disabled?: boolean; message?: string }[];
  wide?: boolean;
  style?: any;
}

const Container = styled.div<{ wide?: boolean }>`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayBorder};
  height: 36px;
  ${(props) => (props.wide ? 'display: flex;' : '')}
`;

interface ITab {
  isActive: boolean;
  wide?: boolean;
}

const Tab = styled.button<ITab>`
  padding: 8px 16px;
  color: ${(props) => (props.isActive ? props.theme.colors.primary : props.theme.colors.inactive)};
  font-size: 14px;
  font-weight: 500;
  border: none;
  background: none;
  outline: none;
  ${(props) => props.isActive && `border-bottom: 2px solid ${props.theme.colors.primary};`}
  cursor: ${(props) => {
    if (props.disabled) return 'not-allowed';
    return props.isActive ? 'default' : 'pointer';
  }};
  ${(props) => (props.wide ? 'flex: 1;' : '')}
`;

const Tabs = ({ onChange, value, tabs, wide, ...props }: IProps) => (
  <Container wide={wide} {...props}>
    {tabs.map((tab) => (
      <Tab
        key={tab.value}
        type="button"
        isActive={tab.value === value}
        onClick={() => onChange(tab.value)}
        disabled={tab.disabled}
        wide={wide}
      >
        {tab.disabled ? <Tooltip title={tab.message}>{tab.label}</Tooltip> : tab.label}
      </Tab>
    ))}
  </Container>
);

export default Tabs;
