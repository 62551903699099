import { Table as AntTable } from 'antd';
import styled from 'styled-components';

interface ITable {
  shadow?: boolean;
  noBorder?: boolean;
  autoHeight?: boolean;
  isClickable?: boolean;
}

export const StyledTable = styled(AntTable)<ITable>`
  ${(props) => props.shadow && `box-shadow: ${props.theme.tableShadow};`}

  .ant-table-content {
    ${(props) => !props.autoHeight && 'min-height: 460px;'}
    border: ${(props) => (props.noBorder ? 'none' : `1px solid ${props.theme.colors.gray}`)};
    border-radius: 4px;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th,
  .ant-table-thead > tr > td {
    padding: 12px 16px;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray};
    background: white;
    color: ${(props) => props.theme.colors.primaryText};

    &:before {
      display: none;
    }
  }

  && .ant-table-selection-column {
    padding: 0.938rem;
  }

  .ant-table-thead > tr > th {
    font-weight: 500;
    max-width: 30%;
    background: ${(props) => props.theme.colors.tableHeader};

    &:first-child {
      border-start-start-radius: 4px !important;
    }
    &:last-child {
      border-start-end-radius: 4px !important;
    }
  }

  .ant-table-row {
    cursor: ${(props) => (props.isClickable ? 'pointer' : 'auto')};

    & td {
      word-break: break-word;
      max-width: 0;

      & .ant-row {
        flex-flow: inherit;
      }
    }

    &:last-child td {
      border-bottom: none;

      &:first-child {
        border-end-start-radius: 4px !important;
      }
      &:last-child {
        border-end-end-radius: 4px !important;
      }
    }
  }

  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: ${(props) => props.theme.colors.primary};
  }

  .ant-typography {
    margin-bottom: 0;
  }
`;
