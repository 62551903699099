import { useContext } from 'react';

import { NotificationContext } from '../context/NotificationProvider';

export { default as useAuthenticated } from './useAuthenticated';
export { default as useConsentTemplateFetch } from './useConsentTemplateFetch';
export { default as useKeyPress } from './useKeyPress';
export { default as useSupportedLanguages } from './useSupportedLanguages';
export { default as useOnClickOutside } from './useOnClickOutside';
export { default as useDebounce } from './useDebounce';
export { default as useAccess } from './useAccess';

const useNotification = () => useContext(NotificationContext);

export { useNotification };
