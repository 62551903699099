import React from 'react';
import LanguageIcon from 'assets/icons/language.svg';
import CheckMark from 'assets/icons/CheckMark.png';

import { Wrapper, StyledButton, Icon, StyledText } from './styled';

type AutotranslateButtonProps = {
  onClick: () => void;
  translated: boolean;
  translating: boolean;
};

const AutotranslateButton = ({ onClick, translating, translated, ...props }: AutotranslateButtonProps) => (
  <Wrapper>
    {translated ? (
      <StyledText>
        <img src={CheckMark} alt="check_mark" />
        Translation successful. Please revalidate the translations before saving.
      </StyledText>
    ) : (
      <StyledButton type="button" onClick={() => onClick()} {...props}>
        <Icon src={LanguageIcon} alt="world-icon" translating={translating} />
        {translating ? 'Translating...' : 'Autotranslate to all languages'}
      </StyledButton>
    )}
  </Wrapper>
);

export default AutotranslateButton;
