import styled from 'styled-components';
import { Modal } from 'antd';

export const Container = styled(Modal)`
  & .ant-modal-content {
    border-radius: 8px;
    padding: 0;
  }

  & .ant-modal-header {
    margin: 0;
    padding: 16px 20px;
    border-bottom: 1px solid ${(props) => props.theme.colors.grayBorder};

    & .ant-modal-title {
      color: ${(props) => props.theme.colors.textGray};
    }
  }

  & .ant-modal-body {
    padding: 0;
    max-height: 80vh;
    overflow-y: auto;
  }

  & .ant-modal-footer {
    margin: 0;
    padding: 16px 20px;
    border-top: 1px solid ${(props) => props.theme.colors.grayBorder};
  }
`;
