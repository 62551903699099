import styled from 'styled-components';

export default styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.hintMessage};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;
