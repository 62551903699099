import { IComponent } from 'lib/Intefaces/IComponent';
import { getDate, getTime } from 'lib/helpers/parseDate';

const COMP_LS_KEY = 'repo_tool_components';
const SCANS_LS_KEY = 'repo_tool_scans';

const saveComponents = (data: IComponent[]) => {
  localStorage.setItem(COMP_LS_KEY, JSON.stringify(data));
};

const getComponents = () => {
  try {
    return JSON.parse(localStorage.getItem(COMP_LS_KEY) || '[]');
  } catch (e) {
    return [];
  }
};

const SCAN_RESULTS = [
  {
    id: '1',
    scannedDate: 'Sun Nov 12 2023 12:36:15 UTC (Coordinated Universal Time)',
  },
  {
    id: '2',
    scannedDate: 'Sun Nov 12 2020 06:53:32 GMT+0200 (Eastern European Standard Time)',
  },
  {
    id: '3',
    scannedDate: 'Sun Nov 12 2021 23:12:02 GMT+0200 (Eastern European Standard Time)',
  },
];

localStorage.setItem(SCANS_LS_KEY, JSON.stringify(SCAN_RESULTS));

const getScans = () => {
  try {
    return JSON.parse(localStorage.getItem(SCANS_LS_KEY) || '[]');
  } catch (e) {
    return [];
  }
};

export const searchComponents = (q: string): Promise<IComponent[]> =>
  new Promise((resolve) => {
    const components = getComponents();
    setTimeout(() => {
      resolve(
        components.filter(
          (c: IComponent) =>
            c.name.toLowerCase().includes(q.toLowerCase()) ||
            c.bundleId === q ||
            c.category.toLowerCase().includes(q.toLowerCase()),
        ),
      );
    }, 1000);
  });

export const getComponent = (id: string) => {
  const components = getComponents();
  return components.find((c: IComponent) => c.bundleId === id);
};

export const updateComponent = (component: IComponent) => {
  const components = getComponents();
  saveComponents(components.map((c: IComponent) => (c.bundleId === component.bundleId ? component : c)));
  return component;
};

export const createComponent = (component: IComponent) => {
  const components = getComponents();
  const bundleId = `${new Date().getTime()}`;
  const c = { ...component, bundleId };
  saveComponents([...components, c]);
  return c;
};

export const getScanResults = (id: string) => {
  const scans = getScans();
  return scans.map((s: any) => ({
    ...s,
    date: getDate(s.scannedDate),
    time: getTime(s.scannedDate),
  }));
};

export const startScan = (id: string) => {
  // ...
};

export const getScanStatus = (id: string) => {
  // ...
};
