import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import AUTH_USER_MUTATION from 'lib/gql/mutations/authUser';
import { useAuth0 } from '@auth0/auth0-react';
import { setContext } from '@apollo/client/link/context';

export default function useAuth() {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);

  const cache = new InMemoryCache();

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_APOLLO_CLIENT}`,
    credentials: 'include',
  });
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        withCredentials: true,
      },
    };
  });

  const client = new ApolloClient({
    cache,
    link: authLink.concat(httpLink),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
  });

  useEffect(() => {
    setIsLoading(true);

    getAccessTokenSilently()
      .then((responseToken: any) => client.mutate({ mutation: AUTH_USER_MUTATION(responseToken) }))
      .then(() => {
        setIsLoading(false);
        if (pathname !== '/') {
          return push('/');
        }
        return null;
      })
      .catch(() => {
        setIsLoading(false);
        if (pathname !== '/login') {
          return push('/login');
        }
        return null;
      });
  }, []);

  return { isLoading, client };
}
