import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import DropdownIcon from 'assets/icons/dropdown-white.svg';
import { useOnClickOutside } from 'lib/hooks';

type StyleType = 'primary' | 'white' | 'inline' | 'secondary' | 'red';
type Size = 'default' | 'small';

const primary = css`
  background: ${(props) => props.theme.colors.primary};
  padding: 0 16px;
  border: none;
  height: 40px;
`;

const red = css`
  background: ${(props) => props.theme.colors.warning};
  color: white;
  border: none;
`;

const white = css`
  background: white;
  padding: 0 12px;
  border: 1px solid ${(props) => props.theme.colors.buttonBorder};
  height: 40px;
`;

const secondary = css`
  background: none;
  padding: 0 16px;
  border: 1px solid ${(props) => props.theme.colors.buttonBorder};
  height: 40px;
  color: ${(props) => props.theme.colors.hintMessage};
`;

const inline = css`
  color: ${(props) => props.theme.colors.primary};
  background: none;
  display: inline;
  border: none;
  font-weight: 500;
`;

const styles = { primary, white, inline, secondary, red };

const small = css`
  height: 32px;
  padding: 0 12px;
`;

const disabledStyle = css`
  cursor: not-allowed;
  opacity: 0.5;
`;

const Options = styled.div`
  position: absolute;
  bottom: calc(100% + 8px);
  background: white;
  color: black;
  z-index: 20;
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

  & div {
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 4px;

    &:hover {
      background: #f2f6fd;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const SButton = styled.button<{ size: Size; styleType: StyleType }>`
  position: relative;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => styles[props.styleType]};
  ${(props) => ['primary', 'secondary', 'red'].includes(props.styleType) && props.size === 'small' && small};
  ${(props) => props.disabled && disabledStyle};
`;

const Loading = styled.div`
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border: 2px solid white;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface IProps {
  children: React.ReactNode;
  onClick: () => void;
  style?: any;
  styleType?: StyleType;
  size?: Size;
  disabled?: boolean;
  loading?: boolean;
  dropdown?: boolean;
  options?: { label: string; onClick: () => void }[];
}

const Button = ({
  children,
  loading,
  disabled,
  onClick,
  size = 'default',
  styleType = 'primary',
  dropdown,
  options,
  ...props
}: IProps) => {
  const container = useRef(null);
  const [showOptions, setShowOptions] = useState(false);

  useOnClickOutside(container, () => setShowOptions(false));

  return (
    <SButton
      onClick={dropdown ? () => setShowOptions(!showOptions) : onClick}
      styleType={styleType}
      size={size}
      disabled={disabled || loading}
      ref={container}
      {...props}
    >
      {loading && <Loading />}
      {children}
      {dropdown && <img src={DropdownIcon} />}
      {showOptions && (
        <Options>
          {options &&
            options.map((option) => (
              <div key={option.label} onClick={option.onClick}>
                {option.label}
              </div>
            ))}
        </Options>
      )}
    </SButton>
  );
};

export default Button;
