export enum Category {
  NECESSARY = 'Necessary',
  PREFERENCE = 'Preference',
  STATISTICS = 'Statistics',
  MARKETING = 'Marketing',
}

export enum ComponentType {
  WIX_STANDARD = 'Wix standard component',
  THIRD_PARTY_APP = 'Third-party app',
  CUSTOM_EMBED = 'Custom Embed',
}

type Regex = {
  regex: string;
  example: string;
};

export interface Service {
  name: string;
  category: Category;
  templateId: string;
  regexes: Regex[];
}

export interface IComponent {
  templateId: string;
  bundleId: string;
  name: string;
  category: keyof typeof Category;
  services: Service[];
  lastScanned: string;
  status: boolean; // mapped or unmapped
  description: string;
  canaryUrl: string;
  manifestName: string;
  uniqueRegexes: Regex[];
  isActive: boolean;
  componentType: keyof typeof ComponentType;
  componentLink: string;
}

export const EMPTY_COMPONENT: IComponent = {
  templateId: '',
  bundleId: '',
  name: '',
  category: 'MARKETING',
  services: [],
  lastScanned: '',
  status: false,
  description: '',
  canaryUrl: '',
  manifestName: '',
  uniqueRegexes: [],
  isActive: false,
  componentType: 'WIX_STANDARD',
  componentLink: '',
};

export interface IScanResult {
  id: string;
  date: string;
  time: string;
}
