import styled from 'styled-components';
import { Tabs } from 'components';

export const Name = styled.h4`
  font-size: 14px;
  color: black;
  font-weight: 600;
`;

export const Id = styled.span`
  font-size: 14px;
  color: ${(props) => props.theme.colors.textGray};
`;

export const Active = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.textGray};
  margin-right: 8px;
`;

export const WiderTabs = styled(Tabs)`
  display: flex;
  justify-content: center;

  & button {
    width: 200px;
  }
`;

export const Content = styled.div`
  padding: 20px;
  background: ${(props) => props.theme.colors.lightGrayBackground};
`;
