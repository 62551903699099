import { useEffect } from 'react';
import GET_CONSENT_TEMPLATE from 'lib/gql/queries/getConsentTemplate';
import { useLazyQuery } from '@apollo/client';
import useDebounce from './useDebounce';
import ITemplate from 'lib/Intefaces/ITemplate';

export default function useConsentTemplateFetch(value: string, debounceTime: number) {
  const debounceValue = useDebounce(value, debounceTime);
  const [loadData, { data, loading, error }] = useLazyQuery(GET_CONSENT_TEMPLATE);

  useEffect(() => {
    if (debounceValue && debounceValue.length >= 3) {
      loadData({ variables: { searchTerm: debounceValue } });
    }
  }, [debounceValue]);

  const responseData: Array<ITemplate> = data ? data.getConsentTemplateByDataProcessorOrTemplateId : [];

  return {
    responseData,
    loadingTemplates: loading,
    loadTemplatesError: error,
    loadData,
  };
}
