import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from 'pages/App';
import NotificationProvider from 'lib/context/NotificationProvider';
import { Auth0Provider } from '@auth0/auth0-react';
import './index.css';

console.log(
  `%cUsercentrics - Template App - v${process.env.REACT_APP_VERSION}`,
  'font-weight: bold; color: #1d60b3; font-size: 14px',
);

if (process.env.REACT_APP_BUILD_VERSION !== 'localhost') {
  Sentry.init({
    dsn: 'https://b7d8fa4fedec45919bae88c1101fca43@o513113.ingest.sentry.io/5614410',
    integrations: [new Integrations.BrowserTracing()],

    // Sampler for performance data (currently turned off)
    tracesSampleRate: 0.0,
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Auth0Provider
    domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
    clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
    audience={`${process.env.REACT_APP_AUTH0_AUDIENCE}`}
    redirectUri={
      window.location.port
        ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}/login`
        : `${window.location.protocol}//${window.location.hostname}/login`
    }
  >
    <NotificationProvider>
      <Router>
        <App />
      </Router>
    </NotificationProvider>
  </Auth0Provider>,
);
