import styled from 'styled-components';
import { Select } from 'antd';
import { SearchInput, Dropdown } from 'components';

export const Container = styled.div`
  margin-top: 24px;
  width: 100%;
`;

export const SDropdown = styled(Dropdown)`
  border-radius: 4px 0 0 4px;
  border-right: none;
  align-self: stretch;
  min-height: 40px;
  height: auto;
  width: auto;
  background: ${(props) => props.theme.colors.gray};
`;

export const SSearchInput = styled(SearchInput)`
  border-radius: 0 4px 4px 0;
`;

export const STagSelect = styled(Select)`
  box-shadow: 0 0 0 0 rgba(2, 71, 123, 0.19);
  min-height: 40px;

  & .ant-select-selector {
    min-height: 40px;
    border-radius: 0 4px 4px 0;
    border: 1px solid ${(props) => props.theme.colors.buttonBorder};
  }

  &:hover .ant-select-selector {
    border-color: ${(props) => props.theme.colors.primary} !important;
  }

  &.ant-select-focused {
    & .ant-select-selector {
      border-color: ${(props) => props.theme.colors.primary} !important;
      box-shadow: 0 0 0 2px rgba(2, 71, 123, 0.19);
    }
  }
`;
