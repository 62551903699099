import React, { useState, useEffect } from 'react';
import { Table, Button } from 'components';
import { IScanResult } from 'lib/Intefaces/IComponent';
import { getScanResults } from 'api/repoTool';

const ScanResults = ({ componentId }: { componentId: string }) => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<IScanResult[]>([]);

  const downloadJSON = (id: string) => {
    // TODO: Use real data later here
    const content = { id, results: 'test scan results', scannedDate: new Date() };
    const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(content));
    const dlAnchorElem = document.createElement('a');
    dlAnchorElem.setAttribute('href', dataStr);
    dlAnchorElem.setAttribute('download', `[${componentId}]_${content.scannedDate}_scan_results.json`);
    dlAnchorElem.click();
    dlAnchorElem.remove();
  };

  useEffect(() => {
    setLoading(true);
    setResults([]);

    (async () => {
      const r = await getScanResults(componentId);
      setResults(r);
      setLoading(false);
    })();
  }, [componentId]);

  return (
    <Table
      loading={loading}
      autoHeight
      columns={[
        {
          title: 'Date',
          key: 'date',
          render: ({ date }: IScanResult) => <span>{date}</span>,
        },
        {
          title: 'Time',
          key: 'time',
          render: ({ time }: IScanResult) => <span>{time}</span>,
        },
        {
          title: 'Action',
          key: 'action',
          render: ({ id }: IScanResult) => (
            <Button onClick={() => downloadJSON(id)} styleType="inline">
              Download JSON
            </Button>
          ),
        },
      ]}
      data={results.map((r) => ({ ...r, key: r.id }))}
    />
  );
};

export default ScanResults;
