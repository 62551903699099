import { gql } from '@apollo/client';

const NEW_TEMPLATE = gql`
  mutation createConsentTemplate(
    $dataProcessor: String!
    $descriptionOfService: String!
    $nameOfProcessingCompany: String!
    $addressOfProcessingCompany: String!
    $dataProtectionOfficer: String
    $privacyPolicyURL: String
    $cookiePolicyURL: String
    $iabId: String
    $iabv2Id: String
    $dataRecipientsList: String
    $dataPurposesList: [String]
    $dataCollectedList: [String]
    $locationOfProcessing: String
    $thirdCountryTransfer: String
    $retentionPeriodDescription: String
    $technologyUsed: [String]
    $legalBasisList: [String!]!
    $optOutUrl: String
    $defaultCategorySlug: String!
    $cookieMaxAgeSeconds: Int
    $usesNonCookieAccess: Boolean
    $deviceStorageDisclosureUrl: String
    $deviceStorage: DeviceStorageInput
  ) {
    createConsentTemplate(
      consentTemplate: {
        type: "predefined"
        language: "en"
        dataProcessor: $dataProcessor
        descriptionOfService: $descriptionOfService
        nameOfProcessingCompany: $nameOfProcessingCompany
        addressOfProcessingCompany: $addressOfProcessingCompany
        dataProtectionOfficer: $dataProtectionOfficer
        privacyPolicyURL: $privacyPolicyURL
        cookiePolicyURL: $cookiePolicyURL
        iabId: $iabId
        iabv2Id: $iabv2Id
        dataRecipientsList: [$dataRecipientsList]
        dataPurposesList: $dataPurposesList
        dataCollectedList: $dataCollectedList
        locationOfProcessing: $locationOfProcessing
        thirdCountryTransfer: $thirdCountryTransfer
        retentionPeriodDescription: $retentionPeriodDescription
        technologyUsed: $technologyUsed
        legalBasisList: $legalBasisList
        optOutUrl: $optOutUrl
        defaultCategorySlug: $defaultCategorySlug
        cookieMaxAgeSeconds: $cookieMaxAgeSeconds
        usesNonCookieAccess: $usesNonCookieAccess
        deviceStorageDisclosureUrl: $deviceStorageDisclosureUrl
        deviceStorage: $deviceStorage
      }
    ) {
      templateId
      dataProcessor
      thirdCountryTransfer
      retentionPeriodDescription
      cookieMaxAgeSeconds
      usesNonCookieAccess
      deviceStorageDisclosureUrl
      deviceStorage {
        disclosures {
          name
          type
          domain
          description
        }
      }
    }
  }
`;

export default NEW_TEMPLATE;
