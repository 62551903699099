import React, { useEffect, useState, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { Form } from 'antd';
import { useMutation } from '@apollo/client';
import { useConsentTemplateFetch, useSupportedLanguages, useNotification, useAccess } from 'lib/hooks';
import { inputValues, notLanguageSpecificFields } from 'lib/predefinedValues/constants';
import {
  setInitialValuesForEdit,
  prepareDataBeforePost,
  validateData,
  carryOverValues,
} from 'lib/predefinedValues/validation';
import ITemplate from 'lib/Intefaces/ITemplate';
import IUpdateVersion from 'lib/Intefaces/IUpdateVersion';
import { DeprecatedLabel, TranslationSettings, AddNewLanguages, Flex, Dropdown, Modal, Button } from 'components';

import TRANSLATE_CONSENT_TEMPLATE from 'lib/gql/mutations/translateConsentTemplate';

import StorageInformation from './Tabs/StorageInformation';
import ServiceInformation from './Tabs/ServiceInformation';
import Details from './Tabs/Details';

import { FormWrapper, TemplateInfo, TemplateName, TitleWrapper, LanguagesWrapper, Content, WiderTabs } from './styled';

type ModalProps = {
  open: boolean;
  onClose: () => void;
  template: ITemplate;
};

enum TabsEnum {
  SERVICE_INFO = 'SERVICE_INFO',
  STORAGE_INFO = 'STORAGE_INFO',
  DETAILS = 'DETAILS',
}

const EditTemplateModal = ({ open, onClose, template }: ModalProps) => {
  const [form] = Form.useForm();
  const { isReadAccess } = useAccess();
  const notification = useNotification();
  const { responseData, loadTemplatesError } = useConsentTemplateFetch(template ? template.templateId : '', 0);
  const [tab, setTab] = useState<TabsEnum>(TabsEnum.SERVICE_INFO);
  const [translateConsentTemplate] = useMutation(TRANSLATE_CONSENT_TEMPLATE);
  const [selectedLanguage, changeLanguage] = useState<string>('en');
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const [carryOverLanguages, setCarryOverLanguages] = useState<Array<string>>([]);

  const existingLanguages = useMemo(() => templates.map((t) => t.language), [templates]);
  const { supportedLanguages, templateLanguages, languagesThatCanBeAdded } = useSupportedLanguages(existingLanguages);

  const onLanguageChange = (language: string) => {
    if (existingLanguages.includes(language)) {
      if (carryOverLanguages.includes(language)) {
        const originalTemplate = templates.find((t) => t.language === language) as ITemplate;
        const updatedTemplate = carryOverValues(originalTemplate, templates, carryOverLanguages);

        form.setFieldsValue(updatedTemplate);
        setTemplates(templates.map((t) => (t.language === language ? updatedTemplate : t)));
      } else {
        form.setFieldsValue(templates.find((t) => t.language === language));
      }
      return changeLanguage(language);
    }
  };

  const addMultipleLanguages = (languages: Array<string>) => {
    const engTemplate = templates.find((t) => t.language === 'en');
    const newTemplates: ITemplate[] = languages.map((language) => ({
      ...inputValues,
      language,
      templateId: engTemplate?.templateId || '',
      nameOfProcessingCompany: engTemplate?.nameOfProcessingCompany || '',
      addressOfProcessingCompany: engTemplate?.addressOfProcessingCompany || '',
      dataProtectionOfficer: engTemplate?.dataProtectionOfficer || '',
      privacyPolicyURL: engTemplate?.privacyPolicyURL || '',
      cookiePolicyURL: engTemplate?.cookiePolicyURL || '',
      dataRecipientsList: engTemplate?.dataRecipientsList || '',
      optOutUrl: engTemplate?.optOutUrl || '',
      dataProcessor: engTemplate?.dataProcessor || '',
      defaultCategorySlug: engTemplate?.defaultCategorySlug || '',
      legalBasisList: engTemplate?.legalBasisList || [],
      technologyUsed: engTemplate?.technologyUsed || [],
      locationOfProcessing: engTemplate?.locationOfProcessing || '',
      thirdCountryTransfer: engTemplate?.thirdCountryTransfer || '',
      dataCollectedList: engTemplate?.dataCollectedList || [],
      dataPurposesList: engTemplate?.dataPurposesList || [],
      cookieMaxAgeSeconds: engTemplate?.cookieMaxAgeSeconds || '',
      usesNonCookieAccess: engTemplate?.usesNonCookieAccess || false,
      deviceStorageDisclosureUrl: engTemplate?.deviceStorageDisclosureUrl || '',
      deviceStorage: engTemplate?.deviceStorage || { disclosures: [] },
      iabId: engTemplate?.iabId || '',
      iabv2Id: engTemplate?.iabv2Id || '',
      retentionPeriodDescription: engTemplate?.retentionPeriodDescription || '',
    }));

    setTemplates((ts) => [...ts, ...newTemplates]);
  };

  const updateTemplate = (version: IUpdateVersion) => {
    const carriedOverValues = templates.map((t) => carryOverValues(t, templates, carryOverLanguages));
    const postData = prepareDataBeforePost(carriedOverValues);

    translateConsentTemplate({
      variables: {
        templateId: templates[0].templateId,
        updateVersion: version,
        templates: postData.reduce((acc, curr) => {
          const { language, templateId, dataProcessor, ...requestBody } = curr;
          return {
            ...acc,
            [language]: {
              ...requestBody,
              thirdCountryTransfer: Array.isArray(Object(requestBody).thirdCountryTransfer)
                ? Object(requestBody).thirdCountryTransfer.join(',')
                : Object(requestBody).thirdCountryTransfer,
              locationOfProcessing: Array.isArray(Object(requestBody).locationOfProcessing)
                ? Object(requestBody).locationOfProcessing.join(',')
                : Object(requestBody).locationOfProcessing,
            },
          };
        }, {}),
      },
    })
      .then(() => {
        onClose();
        notification.notify({ notificationMessage: 'Updated successfully', type: 'success' });
      })
      .catch((error: any) => {
        // note: error.networkError.result.errors is a workaround because error.graphQLErrors is empty
        const graphQlErrors = error?.networkError?.result?.errors || [];
        Sentry.captureException(error, { extra: { graphQlError: graphQlErrors } });
        notification.notify({ notificationMessage: error.message, type: 'error' });
        if (graphQlErrors.length > 0) {
          notification.notify({
            notificationMessage: 'API error: ' + graphQlErrors[0]?.message,
            type: 'error',
          });
        }
        setSaving(false);
      });
  };

  const onSubmit = (version: IUpdateVersion) => {
    setSaving(true);
    const isDataValid = validateData(templates);
    if (!isDataValid) {
      notification.notify({
        notificationMessage: 'Invalid values have been detected. Please correct them.',
        type: 'warning',
      });
      setSaving(false);
    } else {
      updateTemplate(version);
    }
  };

  // When there are changes in the form we move them to templates state as well
  const onFieldsChange = (change: any) => {
    const field = Object.keys(change)[0];
    const value = change[field];
    // Predefined fields that are not language specific, like data purposes, legal basis, etc.
    // We carry over the values to other languages
    if (Array.isArray(value) && (Array.isArray(value) || notLanguageSpecificFields.includes(field))) {
      setTemplates((tmplts) => tmplts.map((t) => ({ ...t, [field]: value })));
    }
    const values = form.getFieldsValue(true);
    setTemplates((tmplts) => tmplts.map((t) => (t.language === values.language ? values : t)));
  };

  useEffect(() => {
    setTemplates(setInitialValuesForEdit(responseData));
  }, [responseData]);

  useEffect(() => {
    if (loadTemplatesError) notification.notify({ notificationMessage: loadTemplatesError.message, type: 'error' });
  }, [loadTemplatesError, notification]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={isReadAccess ? 'View Template' : 'Edit Data Processing Template'}
      width="640px"
      footer={
        <Flex justifyContent="space-between" alignItems="center">
          <Button styleType="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            styleType="primary"
            dropdown
            onClick={() => null}
            loading={saving}
            options={[
              { label: 'Major (1.x.x)', onClick: () => setShowConfirmation(true) },
              { label: 'Minor (x.1.x)', onClick: () => onSubmit(IUpdateVersion.Minor) },
              { label: 'Patch (x.x.1)', onClick: () => onSubmit(IUpdateVersion.Patch) },
            ]}
          >
            Save
          </Button>
        </Flex>
      }
    >
      {showConfirmation && (
        <Modal
          open={showConfirmation}
          onClose={() => setShowConfirmation(false)}
          title="Major change"
          width="400px"
          footer={
            <Flex justifyContent="space-between" alignItems="center">
              <Button styleType="secondary" size="small" onClick={() => setShowConfirmation(false)}>
                Cancel
              </Button>
              <Button styleType="primary" size="small" onClick={() => onSubmit(IUpdateVersion.Major)} loading={saving}>
                Confirm
              </Button>
            </Flex>
          }
        >
          <p style={{ padding: '12px' }}>
            Please confirm that you want to save a major change for this Data Processing Template. This might cause a
            <span style={{ fontWeight: 'bold' }}> CMP resurface for customers.</span>
          </p>
        </Modal>
      )}
      <>
        <TemplateInfo>
          <Flex justifyContent="space-between" alignItems="flex-start">
            <TitleWrapper>
              <div>
                <TemplateName bold>{template.dataProcessor}</TemplateName>
                <TemplateName>{template.templateId}</TemplateName>
              </div>
              {template.isDeprecated && <DeprecatedLabel style={{ marginLeft: '12px' }} />}
            </TitleWrapper>

            {((isReadAccess && templateLanguages.length > 0) || supportedLanguages.length > 0) && (
              <LanguagesWrapper>
                {languagesThatCanBeAdded.length > 0 && (
                  <AddNewLanguages
                    languagesThatCanBeAdded={languagesThatCanBeAdded}
                    addMultipleLanguages={(languages) => addMultipleLanguages(languages)}
                  />
                )}
                <Dropdown
                  value={selectedLanguage}
                  onChange={onLanguageChange}
                  items={templateLanguages}
                  style={{ borderRadius: languagesThatCanBeAdded.length > 0 ? '0 4px 4px 0' : '4px' }}
                />
              </LanguagesWrapper>
            )}
          </Flex>
          <WiderTabs
            value={tab}
            onChange={(value) => setTab(value as TabsEnum)}
            tabs={[
              { label: 'Service Information', value: TabsEnum.SERVICE_INFO },
              { label: 'Storage Information', value: TabsEnum.STORAGE_INFO },
              { label: 'Details', value: TabsEnum.DETAILS },
            ]}
          />
        </TemplateInfo>
        <Content style={{ marginBottom: '46px' }}>
          <FormWrapper editTemplate>
            <Form
              form={form}
              layout="vertical"
              onValuesChange={onFieldsChange}
              initialValues={setInitialValuesForEdit([template])[0]}
            >
              {tab === TabsEnum.SERVICE_INFO && (
                <ServiceInformation
                  selectedLanguage={selectedLanguage}
                  existingLanguages={existingLanguages}
                  setTemplates={setTemplates}
                  editTemplate
                  form={form}
                />
              )}
              {tab === TabsEnum.STORAGE_INFO && (
                <StorageInformation
                  selectedLanguage={selectedLanguage}
                  setTemplates={setTemplates}
                  editTemplate
                  existingLanguages={existingLanguages}
                  form={form}
                />
              )}
              {tab === TabsEnum.DETAILS && <Details template={template} />}
            </Form>
          </FormWrapper>
        </Content>
        {!isReadAccess && (
          <TranslationSettings
            disabled={selectedLanguage !== 'en'}
            existingLanguages={existingLanguages}
            selectedLanguages={carryOverLanguages}
            onChange={(lngs) => {
              setCarryOverLanguages(lngs);
            }}
          />
        )}
      </>
    </Modal>
  );
};

export default EditTemplateModal;
