import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAccess } from 'lib/hooks';
import { USERS_ALLOWED_TO_USE_WIX_REPO } from 'lib/consts';
import { Header, Footer, Tabs, Flex } from 'components';
import RepositoryTool from '../RepositoryTool';
import DataProcessingServices from '../DataProcessingServices';
import { PageContent, Title } from './styled';

type MainProps = {
  logout: () => void;
};

enum TabsEnum {
  DPS = 'dps',
  WIX_REPO = 'wix-repo',
}

const Main = ({ logout }: MainProps) => {
  const [tab, setTab] = useState<TabsEnum>(TabsEnum.DPS);
  const { isAdmin, isReadAccess } = useAccess();
  const { user } = useAuth0();

  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="flex-start">
      <Header isReadAccess={isReadAccess} logout={logout} />
      {(isAdmin || isReadAccess) && (
        <PageContent>
          <Title>Usercentrics Template App</Title>
          <Tabs
            value={tab}
            onChange={(value) => setTab(value as TabsEnum)}
            tabs={[
              { label: 'Data Processing Services', value: TabsEnum.DPS },
              {
                label: 'WIX Repository',
                value: TabsEnum.WIX_REPO,
                disabled: !USERS_ALLOWED_TO_USE_WIX_REPO.includes(user?.email || ''),
                message: 'Coming soon',
              },
            ]}
            style={{ marginTop: '24px' }}
          />
          {tab === TabsEnum.DPS && <DataProcessingServices />}
          {tab === TabsEnum.WIX_REPO && <RepositoryTool />}
          <Footer />
        </PageContent>
      )}
    </Flex>
  );
};

export default Main;
