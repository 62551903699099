import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { Modal, Flex, Switch, Button } from 'components';
import { IComponent, EMPTY_COMPONENT } from 'lib/Intefaces/IComponent';
import { getComponent, updateComponent, createComponent } from 'api/repoTool';
import { useAccess } from 'lib/hooks';
import PrimaryComponent from './Content/PrimaryComponent';
import SecondaryServices from './Content/SecondaryServices';
import ScanResults from './Content/ScanResults';
import { Name, Id, Active, WiderTabs, Content } from './styled';

interface IProps {
  onClose: () => void;
  componentId: string | null;
  open: boolean;
  onUpdate: (component: IComponent) => void;
}

enum TabsEnum {
  PRIMARY = 'primary',
  SERVICES = 'services',
  SCAN_RESULTS = 'scan_results',
}

// TODO: Delete later
const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const Component = ({ onUpdate, componentId, open, onClose }: IProps) => {
  const [form] = Form.useForm();
  const { isReadAccess } = useAccess();
  const [component, setComponent] = useState<IComponent | null>(null);
  const [tab, setTab] = useState<TabsEnum>(TabsEnum.PRIMARY);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState<{ uniqueRegexes: boolean; subServiceRegexes: number[] }>({
    uniqueRegexes: false,
    subServiceRegexes: [],
  });

  const onChange = (field: string) => (value: any) => {
    setComponent((c) => ({ ...(c as IComponent), [field]: value }));
  };

  const validate = async () => {
    try {
      const uniqueRegexes = !component?.uniqueRegexes?.length;
      const subServiceRegexes =
        component?.services
          .map((s, i) => ({ ...s, i }))
          .filter((s) => !s.regexes.length)
          .map((s) => s.i) || [];
      setErrors((prev) => ({ ...prev, uniqueRegexes, subServiceRegexes }));
      if (uniqueRegexes || subServiceRegexes.length) {
        throw new Error('Validation failed');
      }
      await form.validateFields();
    } catch (e) {
      throw new Error('Validation failed');
    }
  };

  const onSave = async () => {
    if (component) {
      try {
        await validate();
        setSaving(true);
        if (componentId === 'create') {
          const c = createComponent(component);
          setComponent(c);
        } else if (componentId) {
          await delay(1000);
          updateComponent(component);
          onUpdate(component);
        }
        setSaving(false);
        onClose();
      } catch (e) {
        return;
      }
    }
  };

  useEffect(() => {
    if (component?.uniqueRegexes?.length) {
      setErrors((prev) => ({ ...prev, uniqueRegexes: false }));
    }
  }, [component?.uniqueRegexes]);

  useEffect(() => {
    const subServiceRegexes =
      component?.services
        .map((s, i) => ({ ...s, i }))
        .filter((s) => !s.regexes.length)
        .map((s) => s.i) || [];
    setErrors((prev) => ({ ...prev, subServiceRegexes }));
  }, [component?.services]);

  useEffect(() => {
    setTab(TabsEnum.PRIMARY);
    if (componentId === 'create') {
      form.resetFields();
      setComponent(EMPTY_COMPONENT);
    } else if (componentId) {
      setComponent(getComponent(componentId));
    }
  }, [open, componentId]);

  if (!component) return null;

  return (
    <Modal
      onClose={onClose}
      open={open}
      title={componentId === 'create' ? 'Create Component' : 'Edit Component'}
      width="640px"
      footer={
        <Flex justifyContent="space-between" alignItems="center">
          <Button styleType="secondary" onClick={onClose}>
            Cancel
          </Button>
          {!isReadAccess && (
            <Button styleType="primary" onClick={onSave} loading={saving}>
              {component.bundleId ? 'Save' : 'Create'}
            </Button>
          )}
        </Flex>
      }
    >
      <Flex alignItems="center" justifyContent="space-between" style={{ padding: '20px', paddingBottom: '16px' }}>
        <div>
          <Name>{component.name}</Name>
          <Id>{component.bundleId}</Id>
        </div>
        <Flex>
          <Active>{component.isActive ? 'Active' : 'Inactive'}</Active>
          {!isReadAccess && <Switch checked={component.isActive} onChange={(s) => onChange('isActive')(s)} />}
        </Flex>
      </Flex>
      <WiderTabs
        onChange={(t) => setTab(t as TabsEnum)}
        value={tab}
        tabs={[
          {
            label: 'Primary Component',
            value: TabsEnum.PRIMARY,
          },
          {
            label: 'Secondary Services',
            value: TabsEnum.SERVICES,
          },
          {
            label: 'Scan Results',
            value: TabsEnum.SCAN_RESULTS,
          },
        ]}
      />
      <Content>
        {tab === TabsEnum.PRIMARY && (
          <PrimaryComponent component={component} onChange={setComponent} form={form} errors={errors} />
        )}
        {tab === TabsEnum.SERVICES && (
          <SecondaryServices component={component} onChange={setComponent} form={form} errors={errors} />
        )}
        {tab === TabsEnum.SCAN_RESULTS && <ScanResults componentId={component.bundleId} />}
      </Content>
    </Modal>
  );
};

export default Component;
