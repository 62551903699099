import styled from 'styled-components';

export const AddNewLanguagePopup = styled.div`
  margin-top: 10px;
  z-index: 1;
  position: absolute;
  right: 0;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.grayBorder};
  background: white;
  width: 260px;
  overflow: auto;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
`;

export const CheckboxWrapper = styled.div`
  height: 192px;
  overflow: scroll;
  padding: 12px;
`;

export const ButtonWrapper = styled.div`
  padding: 12px;
  border-top: 1px solid ${(props) => props.theme.colors.grayBorder};
`;
