import { FormInstance, FormListFieldData } from 'antd';
import { Container, RegexInfoButton } from './styled';
import { Button, Flex, Input, Item } from 'components';
import React, { useState } from 'react';

type RegexListItemProps = {
  field: FormListFieldData;
  form: FormInstance<any>;
  isReadOnly: boolean;
  onDelete: () => void;
};

const RegexListItem = ({ field, form, isReadOnly, onDelete }: RegexListItemProps) => {
  const [collapsed, collapseInformation] = useState(false);
  const regex = form.getFieldValue(['requestRegexes', field.name]);

  return (
    <Container>
      <RegexInfoButton
        onClick={() => !regex.newItem && collapseInformation(!collapsed)}
        type="button"
        style={{ marginBottom: collapsed ? 0 : '16px' }}
      >
        {regex.newItem ? 'Add Regex' : regex.regex}
      </RegexInfoButton>
      {!collapsed && (
        <>
          <Flex>
            <Item label="Regex" tooltip="Regex" name={[field.name, 'regex']} colon={false} style={{ width: '100%' }}>
              <Input disabled={isReadOnly} placeholder="Enter regex" />
            </Item>
          </Flex>
          {!isReadOnly && (
            <Flex>
              {regex.newItem ? (
                <>
                  <Button styleType="secondary" size="small" onClick={onDelete} style={{ marginRight: '12px' }}>
                    Cancel
                  </Button>
                  <Button
                    styleType="primary"
                    size="small"
                    onClick={() => {
                      form.setFieldValue(['requestRegexes', field.name, 'newItem'], false);
                      collapseInformation(true);
                    }}
                  >
                    Add
                  </Button>
                </>
              ) : (
                <Button styleType="red" size="small" onClick={onDelete}>
                  Delete
                </Button>
              )}
            </Flex>
          )}
        </>
      )}
    </Container>
  );
};

export default RegexListItem;
