import React, { useEffect } from 'react';
import { Form, FormInstance } from 'antd';
import { Input, Flex, Select, Option, Collapse, Label, Button, Item, ErrorMessage } from 'components';
import { IComponent, Category } from 'lib/Intefaces/IComponent';
import { deepMerge } from 'lib/helpers/objects';
import { useAccess } from 'lib/hooks';

const EMPTY_SERVICE = {
  name: '',
  category: Category.MARKETING,
  templateId: '',
  regexes: [],
};

interface IProps {
  onChange: (value: any) => void;
  component: IComponent;
  form: FormInstance<any>;
  errors: {
    subServiceRegexes: number[];
  };
}

const SecondaryServices = ({ errors, form, component, onChange }: IProps) => {
  const { isReadAccess } = useAccess();

  const onFieldsChange = () => onChange(deepMerge(component, form.getFieldsValue()));

  useEffect(() => {
    form.resetFields();
  }, [component.bundleId]);

  return (
    <Form form={form} layout="vertical" onFieldsChange={onFieldsChange} initialValues={component}>
      <Form.List name="services">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Collapse
                key={field.key}
                title={form.getFieldValue(['services', field.name, 'name']) || 'Service ' + (field.key + 1)}
                style={{ marginBottom: '8px' }}
                onDelete={() => remove(field.name)}
              >
                <Item
                  label="Service Name"
                  colon={false}
                  name={[field.name, 'name']}
                  rules={[{ required: true, message: 'Service name should not be empty' }]}
                >
                  <Input placeholder="Service Name" disabled={isReadAccess} />
                </Item>
                <Flex justifyContent="space-between">
                  <Item
                    label="Category"
                    colon={false}
                    name={[field.name, 'category']}
                    style={{ width: 'calc(50% - 12px)' }}
                    rules={[{ required: true, message: 'Select a category' }]}
                  >
                    <Select placeholder="Select category" size="large" disabled={isReadAccess}>
                      {(Object.keys(Category) as Array<keyof typeof Category>).map((c) => (
                        <Option key={c} value={c}>
                          {Category[c]}
                        </Option>
                      ))}
                    </Select>
                  </Item>
                  <Item
                    label="TemplateID"
                    colon={false}
                    name={[field.name, 'templateId']}
                    style={{ width: 'calc(50% - 12px)' }}
                    rules={[{ required: true, message: 'TemplateID should not be empty' }]}
                  >
                    <Input placeholder="TemplateID" disabled={isReadAccess} />
                  </Item>
                </Flex>
                <Label>Regex(s)</Label>
                {errors.subServiceRegexes.includes(index) && (
                  <ErrorMessage>Service should contain at least one regex.</ErrorMessage>
                )}
                <Form.List name={[field.name, 'regexes']}>
                  {(regexes, { add: addRegex, remove: removeRegex }) => (
                    <>
                      {regexes.map((regexField) => (
                        <Collapse
                          key={regexField.key}
                          title={
                            form.getFieldValue(['services', field.name, 'regexes', regexField.name, 'regex']) ||
                            'Regex ' + (regexField.key + 1)
                          }
                          onDelete={() => removeRegex(regexField.name)}
                          style={{ marginBottom: '8px' }}
                        >
                          <Item
                            label="Regex"
                            colon={false}
                            name={[regexField.name, 'regex']}
                            rules={[{ required: true, message: 'Regex should not be empty' }]}
                          >
                            <Input placeholder="Regex" disabled={isReadAccess} />
                          </Item>
                          <Item label="Example" colon={false} name={[regexField.name, 'example']}>
                            <Input placeholder="Example" disabled={isReadAccess} />
                          </Item>
                        </Collapse>
                      ))}
                      {!isReadAccess && (
                        <Button
                          styleType="secondary"
                          size="small"
                          onClick={() => addRegex({ regex: '', example: '' })}
                          style={{ marginTop: '16px' }}
                        >
                          Add Regex
                        </Button>
                      )}
                    </>
                  )}
                </Form.List>
              </Collapse>
            ))}
            {!isReadAccess && (
              <Button
                styleType="secondary"
                size="small"
                onClick={() => add(EMPTY_SERVICE)}
                style={{ marginTop: '16px' }}
              >
                Add Service
              </Button>
            )}
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default SecondaryServices;
