export const sort = (a = '', b = '') => a.localeCompare(b);

const parseVersion = (versionString: string): [number, number, number] =>
  versionString.split('.').map(Number) as [number, number, number];

export const sortVersions = (a = '0.0.0', b = '0.0.0'): number => {
  const [majorA, minorA, patchA] = parseVersion(a);
  const [majorB, minorB, patchB] = parseVersion(b);

  if (majorA !== majorB) return majorA - majorB;
  if (minorA !== minorB) return minorA - minorB;
  return patchA - patchB;
};
