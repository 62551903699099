import {
  dataCollected,
  dataPurposes,
  dataTransferCountries,
  legalBasis,
  technologyUsed,
} from '@usercentrics/template-app-translations';

export const predefinedConstants = {
  legalBasisList: legalBasis,
  technologyUsed,
  thirdCountryTransfer: dataTransferCountries,
  dataCollectedList: dataCollected,
  locationOfProcessing: dataTransferCountries,
  dataPurposesList: dataPurposes,
};

export const inputs = [
  { name: 'dataProcessor', label: 'Name of Service', type: 'text', required: true },
  { name: 'descriptionOfService', label: 'Description of Service', type: 'textfield' },
  { name: 'nameOfProcessingCompany', label: 'Name of Processing Company', type: 'text' },
  { name: 'addressOfProcessingCompany', label: 'Address of Processing Company', type: 'text' },
  { name: 'dataProtectionOfficer', label: 'Data Protection Officer', type: 'text' },
  { name: 'privacyPolicyURL', label: 'Privacy Policy Link', type: 'text' },
  { name: 'cookiePolicyURL', label: 'Cookie Policy Link', type: 'text' },
  { name: 'iabId', label: 'IAB-ID in Global Vendor List v1', type: 'number' },
  { name: 'iabv2Id', label: 'IAB-ID in Global Vendor List v2', type: 'number' },
  { name: 'dataRecipientsList', label: 'Data Recipients', type: 'text' },
  { name: 'dataPurposesList', label: 'Data Purpose', type: 'tags', items: 'dataPurposes' },
  { name: 'dataCollectedList', label: 'Data Collected', type: 'tags', items: 'dataCollected' },
  {
    name: 'locationOfProcessing',
    label: 'Data Location',
    type: 'tags',
    items: 'dataTransferCountries',
  },
  {
    name: 'thirdCountryTransfer',
    label: 'Data Transfer to Third Countries',
    type: 'tags',
    items: 'dataTransferCountries',
  },
  { name: 'retentionPeriodDescription', label: 'Retention Period', type: 'text' },
  { name: 'technologyUsed', label: 'Technologies Used', type: 'tags', items: 'technologyUsed' },
  // {
  //   name: 'cookiesAndTechnologiesList',
  //   label: 'List of all cookies set by this technology',
  //   type: 'text',
  // },
  { name: 'legalBasisList', label: 'Legal Basis', type: 'tags', items: 'legalBasis' },
  { name: 'optOutUrl', label: 'Opt-out Link', type: 'text' },
  {
    name: 'defaultCategorySlug',
    label: 'Recommended Category',
    type: 'dropdown',
    items: 'defaultCategorySlug',
  },
];

export const inputValues = {
  dataProcessor: '',
  descriptionOfService: '',
  nameOfProcessingCompany: '',
  addressOfProcessingCompany: '',
  dataProtectionOfficer: '',
  privacyPolicyURL: '',
  cookiePolicyURL: '',
  iabId: '',
  templateId: '',
  language: '',
  iabv2Id: '',
  dataRecipientsList: '',
  dataPurposesList: [],
  dataCollectedList: [],
  locationOfProcessing: [],
  thirdCountryTransfer: [],
  retentionPeriodDescription: '',
  technologyUsed: [],
  // cookiesAndTechnologiesList: '',
  legalBasisList: [],
  optOutUrl: '',
  defaultCategorySlug: '',
  cookieMaxAgeSeconds: '',
  usesNonCookieAccess: false,
  deviceStorageDisclosureUrl: '',
  deviceStorage: {
    disclosures: [],
  },
};

export const stringInputs = [
  'dataProcessor',
  'templateId',
  'descriptionOfService',
  'nameOfProcessingCompany',
  'addressOfProcessingCompany',
  'dataProtectionOfficer',
  'privacyPolicyURL',
  'cookiePolicyURL',
  'language',
  'iabId',
  'iabv2Id',
  'dataRecipientsList',
  'retentionPeriodDescription',
  'optOutUrl',
  'defaultCategorySlug',
  'deviceStorageDisclosureUrl',
];

export const notLanguageSpecificFields = ['dataProcessor', 'iabId', 'iabv2Id', 'defaultCategorySlug'];
export const crossLanguageFields = ['retentionPeriodDescription', 'locationOfProcessing', 'descriptionOfService'];
