import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  background-color: white;
  padding: 0 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray};
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > img {
    height: 32px;
  }
`;

export const LogOutButton = styled.button`
  background-color: transparent;
  margin-left: 12px;
  display: flex;
  border: none;
  font-size: 14px;
  color: ${(props) => props.theme.colors.dark};
  align-items: center;
  cursor: pointer;

  & > img:first-child {
    margin-right: 16px;
  }

  & > img:last-child {
    margin-left: 16px;
  }
`;

export const AccessLabel = styled.div`
  display: flex;
  align-items: center;
  padding: 0 12px;
  margin-right: 12px;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  background: ${(props) => props.theme.colors.gray};
  border-radius: 2px;

  &:after {
    display: block;
    content: '';
    position: absolute;
    right: -14px;
    height: 100%;
    border-right: 1px solid ${(props) => props.theme.colors.gray};
  }

  & img {
    margin-right: 4px;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
`;
