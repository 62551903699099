import React from 'react';
import { Form, FormInstance } from 'antd';
import { AddRequestRegexButton } from './styled';
import { nanoid } from 'nanoid';
import RegexListItem from './RegexListItem';
import { Hint, Label } from '../../../../../../components';

const getEmptyRequestRegex = () => ({
  id: nanoid(),
  newItem: true,
  regex: '',
});

type RequestsProps = {
  form: FormInstance<any>;
  isReadOnly: boolean;
};

const Requests = ({ form, isReadOnly }: RequestsProps) => {
  return (
    <>
      <Label>
        Regexes
        <Hint title="Request regexes." />
      </Label>
      <Form.List name="requestRegexes">
        {(fields, { add, remove }) => (
          <>
            {!isReadOnly && (
              <AddRequestRegexButton onClick={() => add(getEmptyRequestRegex())}>
                <span>+</span>Add Regex
              </AddRequestRegexButton>
            )}
            {fields.map((field, index) => (
              <RegexListItem
                key={field.key}
                field={field}
                form={form}
                isReadOnly={isReadOnly}
                onDelete={() => remove(field.name)}
              />
            ))}
          </>
        )}
      </Form.List>
    </>
  );
};

export default Requests;
