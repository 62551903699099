import { gql } from '@apollo/client';

const TRANSLATE_CONSENT_TEMPLATE = gql`
  mutation translateConsentTemplate(
    $templateId: String!
    $templates: ConsentTemplateTranslationsInput
    $updateVersion: UpdateVersion
  ) {
    translateConsentTemplate(
      templateId: $templateId
      type: "predefined"
      templates: $templates
      updateVersion: $updateVersion
    ) {
      templateId
      usesNonCookieAccess
    }
  }
`;

export default TRANSLATE_CONSENT_TEMPLATE;
