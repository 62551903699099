import React from 'react';
import CloseIcon from 'assets/icons/Clear.svg';
import { Container } from './styled';

interface IProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
  width: string;
  footer: React.ReactNode;
}

const Modal = ({ footer, title, open, onClose, children, width }: IProps) => (
  <Container
    width={width}
    onCancel={onClose}
    open={open}
    title={title}
    centered
    maskClosable
    closeIcon={<img src={CloseIcon} />}
    styles={{ mask: { backgroundColor: 'rgb(0 0 0 / 80%)' } }}
    footer={footer}
  >
    {children}
  </Container>
);

export default Modal;
