import React, { useState, useMemo } from 'react';
import { Hint } from 'components';
import CollapseIcon from 'assets/icons/collapse.svg';

import { Checkbox } from 'components';
import { Container, TopRow, Label, Content, CheckboxRow, Languages, LanguageTag, SelectButton } from './styled';

interface IProps {
  existingLanguages: string[];
  selectedLanguages: string[];
  onChange: (languages: string[]) => void;
  disabled?: boolean;
}

const TranslationSettings = ({ existingLanguages, selectedLanguages, onChange, disabled = false }: IProps) => {
  const [checked, toggle] = useState(Boolean(selectedLanguages.length));
  const [showAll, toggleShowAll] = useState(disabled);

  const onSelect = (language: string) => {
    onChange(
      selectedLanguages.includes(language)
        ? selectedLanguages.filter((l) => l !== language)
        : [...selectedLanguages, language],
    );
  };

  const allSelected = useMemo(() => {
    const woEng = existingLanguages.filter((lang) => lang !== 'en');
    return woEng.length === selectedLanguages.length;
  }, [existingLanguages, selectedLanguages]);

  return (
    <Container>
      <TopRow onClick={() => toggleShowAll(!showAll)}>
        <Label>
          Settings for non-translatable fields
          <Hint title="If active, changes will be applied to following fields: Name of Processing Company; Address of Processing Company; Data Protection Officer; Privacy Policy Link; Cookie Policy Link; IAB-ID in Global Vendor List v1; IAB-ID in Global Vendor List v2; Data Recipients; Opt-out link; Recommended Category" />
        </Label>
        <img src={CollapseIcon} style={{ transform: `rotate(${showAll ? 0 : -90}deg)` }} />
      </TopRow>
      {showAll && (
        <Content>
          <CheckboxRow>
            <Checkbox disabled={disabled} checked={checked} onChange={(event) => toggle(event.target.checked)}>
              Save changes for non-translatable fields to other languages
            </Checkbox>
          </CheckboxRow>
          {checked && (
            <Languages>
              {existingLanguages
                .filter((l) => l !== 'en')
                .map((language) => (
                  <LanguageTag
                    disabled={disabled}
                    key={language}
                    selected={selectedLanguages.includes(language)}
                    onClick={disabled ? () => null : () => onSelect(language)}
                  >
                    {language.toUpperCase()}
                  </LanguageTag>
                ))}
            </Languages>
          )}
          {checked && !disabled && (
            <SelectButton onClick={allSelected ? () => onChange([]) : () => onChange(existingLanguages)}>
              {allSelected ? 'De-select all' : 'Select all'}
            </SelectButton>
          )}
        </Content>
      )}
    </Container>
  );
};

export default TranslationSettings;
