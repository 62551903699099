import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.grayBorder};
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};

  & h2 {
    font-size: 16px;
  }
`;

export const StorageInfoButton = styled.button`
  font-size: 16px;
  background-color: transparent;
  border: none;
  text-align: left;
  padding: 0;
  cursor: pointer;
`;
