import React from 'react';
import { Tooltip } from 'antd';
import TooltipIcon from 'assets/icons/Tooltip.svg';

const Hint = ({ title }: { title: string }) => (
  <Tooltip title={title}>
    <img src={TooltipIcon} alt="question-icon" style={{ marginLeft: '4px', opacity: 0.4, width: '14px' }} />
  </Tooltip>
);

export default Hint;
