import HumanizeDuration, { Options as HumanizeOptions } from 'humanize-duration';

interface UnitValues {
  [key: string]: (time: number) => number;
}

const unitMeasuresInSeconds = {
  year: 31557600,
  month: 2629800,
  week: 604800,
  day: 86400,
  hour: 3600,
  minute: 60,
  second: 1,
  miliSecondsMultiplier: 1000,
};

/**
 * Helper to convert time into target unit
 */
const getUnitValues: UnitValues = {
  year: (time: number) => {
    return unitMeasuresInSeconds.year * time;
  },
  month: (time: number) => {
    return unitMeasuresInSeconds.month * time;
  },
  week: (time: number) => {
    return unitMeasuresInSeconds.week * time;
  },
  day: (time: number) => {
    return unitMeasuresInSeconds.day * time;
  },
  hour: (time: number) => {
    return unitMeasuresInSeconds.hour * time;
  },
  minute: (time: number) => {
    return unitMeasuresInSeconds.minute * time;
  },
  second: (time: number) => {
    return unitMeasuresInSeconds.second * time;
  },
};

/**
 * Receives a time string that contains a combination of these options \
 * or at least one (# represents a number) and Converts the string to \
 * the total time in seconds
 * '1 hour' is converted to 3600 seconds.
 * ### \# Year | Month | Week | Day | Hour | Minute | Second
 *
 * @param time - A string value using the mentioned time units
 * @example "1 Year 2 Months"
 * @example "3 years, 2 months, 14 Weeks, 1 hour"
 */
const convertToSeconds = (time: string): number => {
  const unitNames: string[] = Object.keys(unitMeasuresInSeconds);
  let totalTime = 0;
  unitNames.forEach((name: string) => {
    const regexString = `\\b(\\d+\\.?\\d*)\\s*(${name})`;
    const regex = new RegExp(regexString);
    const match = regex.exec(time);
    const value = match && parseInt(match[1], 10);
    if (value) {
      totalTime += getUnitValues[name](value);
    }
  });

  return totalTime;
};

/**
 * Receives a time value in seconds, converts it to a human readable representation
 * @param timeInSeconds - A number value representing time in seconds
 * @example 3600
 * @param config - Optional configuration
 */
const convertToStringFormat = (timeInSeconds: number, config: HumanizeOptions = {}): string => {
  const timeInMilliseconds: number = unitMeasuresInSeconds.miliSecondsMultiplier * timeInSeconds;
  const options = { ...config, fallbacks: config.fallbacks || ['en'] };

  return HumanizeDuration(timeInMilliseconds, options);
};

export { convertToSeconds, convertToStringFormat };
