import React, { useState } from 'react';
import styled from 'styled-components';
import { Flex, Button } from 'components';
import CollapseIcon from 'assets/icons/collapse.svg';
import RemoveIcon from 'assets/icons/remove.svg';

interface IProps {
  title: string;
  children: React.ReactNode;
  onDelete?: () => void;
  style?: any;
}

const DeleteButton = styled(Button)`
  margin-left: auto;
  margin-right: 8px;
  opacity: 0.6;
  transition: 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

const Container = styled.div`
  background: white;
  border: 1px solid ${(props) => props.theme.colors.grayBorder};
  border-radius: 4px;
  padding: 14px 16px;
`;

const Title = styled.h4`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.hintMessage};
`;

const Collapse = ({ title, onDelete, children, ...props }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container {...props}>
      <Flex
        onClick={() => setIsOpen(!isOpen)}
        justifyContent="space-between"
        alignItems="center"
        style={{ cursor: 'pointer' }}
      >
        <Title>{title}</Title>
        {onDelete && (
          <DeleteButton onClick={onDelete} styleType="inline">
            <img src={RemoveIcon} width="20px" alt="remove icon" />
          </DeleteButton>
        )}
        <img src={CollapseIcon} alt="collapse" style={{ transform: isOpen ? 'rotate(180deg)' : '', width: '10px' }} />
      </Flex>
      {isOpen && <div style={{ marginTop: '20px' }}>{children}</div>}
    </Container>
  );
};

export default Collapse;
