import styled, { keyframes } from 'styled-components';

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${(props) => props.theme.colors.primary};
`;

const rotating = keyframes`
  0% { transform: rotate(0); }
  100% { transform: rotate(360deg); }
`;

export const Container = styled.span`
  width: 30px;
  height: 30px;
  margin-bottom: 12px;
  animation: ${rotating} 1.2s infinite linear;
`;

const fading = keyframes`
  0% { opacity: 0.3; }
  100% { opacity: 1; }
`;

export const Dot = styled.i`
  position: absolute;
  display: block;
  width: 14px;
  height: 14px;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  animation: 1000ms linear 0ms infinite alternate none running ${fading};

  &:nth-child(0) {
    left: 0;
    top: 0;
    animation-delay: 0s;
  }
  &:nth-child(1) {
    right: 0;
    top: 0;
    animation-delay: 0.4s;
  }
  &:nth-child(2) {
    right: 0;
    bottom: 0;
    animation-delay: 0.8s;
  }
  &:nth-child(3) {
    left: 0;
    bottom: 0;
    animation-delay: 1.2s;
  }
`;
