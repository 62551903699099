import React from 'react';
import styled from 'styled-components';
import Flex from './Flex';

export const Container = styled(Flex)`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 12px;
  padding: 22px 0;
  margin-top: auto;
  width: 100%;
`;

const Footer = () => (
  <Container justifyContent="center">
    Build {process.env.REACT_APP_BUILD_VERSION} [{process.env.REACT_APP_VERSION}]
  </Container>
);

export default Footer;
