import React from 'react';
import { Overlay, Container, Dot } from './styled';

const Spin = () => (
  <Overlay>
    <Container>
      {[0, 1, 2, 3].map((dot) => (
        <Dot key={dot} style={{}} />
      ))}
    </Container>
    Loading...
  </Overlay>
);

export default Spin;
