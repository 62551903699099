import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 16px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.grayBorder};
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};

  & h2 {
    font-size: 16px;
  }
`;

export const RegexInfoButton = styled.button`
  font-size: 16px;
  background-color: transparent;
  border: none;
  text-align: left;
  padding: 0;
  cursor: pointer;
`;

export const AddRequestRegexButton = styled.button`
  width: 100%;
  padding: 14px;
  border: 1px dashed ${(props) => props.theme.colors.grayBorder};
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  color: ${(props) => props.theme.colors.black};
  font-size: 14px;
  font-weight: 500;

  & span {
    margin-right: 8px;
    font-size: 16px;
    margin-bottom: 0;
  }
`;
