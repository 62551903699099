export default (date: string | undefined) => {
  if (!date) {
    return '';
  }

  const outputDate = new Date(date);
  const year = outputDate.getFullYear();
  const month = outputDate.getMonth() + 1;
  const day = outputDate.getDate();
  const hours = outputDate.getHours();
  const minutes = outputDate.getMinutes();

  return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day} ${
    hours < 10 ? '0' + hours : hours
  }:${minutes < 10 ? '0' + minutes : minutes}`;
};

export const getDate = (date: string) => {
  const outputDate = new Date(date);
  const year = outputDate.getFullYear();
  const month = outputDate.getMonth() + 1;
  const day = outputDate.getDate();

  return `${year}-${month}-${day}`;
};

export const getTime = (date: string) => {
  const outputDate = new Date(date);

  let hours = outputDate.getHours();
  const minutes = ('0' + outputDate.getMinutes()).slice(-2);
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12;

  const timeZone = outputDate.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
  const formattedTime = `${hours}:${minutes} ${ampm} (${timeZone})`;

  return formattedTime;
};
