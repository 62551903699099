import styled from 'styled-components';

export const PageContent = styled.div`
  height: calc(100vh - 64px);
  width: 1000px;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & p {
    margin-top: 8px;
    font-size: 14px;
    color: ${(props) => props.theme.colors.dark};
  }
`;

export const Title = styled.h1`
  margin-top: 50px;
  font-weight: 600;
  font-size: 24px;
  color: ${(props) => props.theme.colors.dark};
`;
