import { useContext } from 'react';

import { AccessContext } from 'lib/context/Access';

const useAccess = () => {
  const { isReadAccess, isAdmin } = useContext(AccessContext);

  return { isReadAccess, isAdmin };
};

export default useAccess;
