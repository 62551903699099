import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useDebounce } from 'lib/hooks';
import SearchIcon from 'assets/icons/search.svg';
import ClearIcon from 'assets/icons/Clear.svg';

const Container = styled.div`
  border: 1px solid ${(props) => props.theme.colors.buttonBorder};
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  background: white;
  box-shadow: 0 0 0 0 rgba(2, 71, 123, 0.19);
  transition: 0.2s;

  &:hover {
    border-color: ${(props) => props.theme.colors.primary};
  }

  &:focus-within {
    border-color: ${(props) => props.theme.colors.primary};
    box-shadow: 0 0 0 2px rgba(2, 71, 123, 0.19);
  }
`;

const Input = styled.input`
  height: 20px;
  margin: 12px;
  font-size: 14px;
  font-weight: 400;
  border: none;
  background: none;
  outline: none;
  flex: 1;

  &::placeholder {
    color: ${(props) => props.theme.colors.buttonBorder};
  }
`;

const Icon = styled.img`
  width: 14px;
  margin: 12px;
  margin-left: auto;
`;

const Loading = styled.div`
  width: 14px;
  height: 14px;
  margin: 12px;
  border: 2px solid ${(props) => props.theme.colors.hintMessage};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Clear = styled.button`
  border-radius: 50%;
  background: ${(props) => props.theme.colors.grayBorder};
  width: 14px;
  height: 14px;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px;

  & img {
    width: 12px;
    height: 12px;
  }
`;

interface IProps {
  onSearch: (value: string) => void;
  value: string;
  onChange: (value: string) => void;
  loading: boolean;
  placeholder: string;
  style?: any;
}

const SearchInput = ({ onSearch, value, onChange, loading, placeholder, ...props }: IProps) => {
  const input = useRef<HTMLInputElement>(null);

  const searchTerm = useDebounce(value, 400);

  useEffect(() => {
    if (searchTerm.length >= 3) {
      onSearch(searchTerm);
    }
  }, [searchTerm]);

  return (
    <Container onClick={() => input.current?.focus()} {...props}>
      <Input ref={input} value={value} onChange={(e) => onChange(e.target.value)} placeholder={placeholder} />
      {loading && <Loading />}
      {!loading &&
        (value.length ? (
          <Clear onClick={() => onChange('')}>
            <img src={ClearIcon} alt="clear icon" />
          </Clear>
        ) : (
          <Icon src={SearchIcon} alt="search icon" />
        ))}
    </Container>
  );
};

export default SearchInput;
