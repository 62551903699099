import React, { forwardRef } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 8px);
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0 0 0 / 40%);
  z-index: 20;
`;

interface IProps {
  children: React.ReactNode;
  style?: any;
  ref?: any;
}

const Tooltip = ({ children, ...props }: IProps, ref: React.ForwardedRef<HTMLDivElement>) => (
  <Container {...props} ref={ref}>
    {children}
  </Container>
);

export default forwardRef(Tooltip);
