import { Form } from 'antd';
import styled from 'styled-components';

const Item = styled(Form.Item)`
  & label {
    font-weight: 500;

    &.ant-form-item-required::before {
      display: none !important;
    }
    &.ant-form-item-required::after {
      content: '*' !important;
      display: block;
      visibility: visible;
    }
  }

  & .ant-form-item-explain-error {
    color: ${(props) => props.theme.colors.warning};
  }
`;

export default Item;
