import { gql } from '@apollo/client';

const AUTH_USER_MUTATION = (authToken: string) => gql`
    mutation {
      authenticateUser(accessToken: "${authToken}") {
        token
      }
    }
  `;

export default AUTH_USER_MUTATION;
