import React from 'react';
import ITemplate from 'lib/Intefaces/ITemplate';
import parseDate from 'lib/helpers/parseDate';

type DetailsProps = {
  template?: ITemplate;
};

const Details = ({ template }: DetailsProps) => (
  <>
    <div style={{ marginBottom: '4px' }}>
      <span style={{ fontWeight: 'bold' }}>Template ID:</span>
      &nbsp;
      <span>{template?.templateId}</span>
    </div>
    <div style={{ marginBottom: '4px' }}>
      <span style={{ fontWeight: 'bold' }}>Version:</span>
      &nbsp;
      <span>{template?.version}</span>
    </div>
    <div style={{ marginBottom: '4px' }}>
      <span style={{ fontWeight: 'bold' }}>Updated by:</span>
      &nbsp;
      <span>{template?.updatedBy}</span>
    </div>
    <div style={{ marginBottom: '4px' }}>
      <span style={{ fontWeight: 'bold' }}>Updated at:</span>
      &nbsp;
      <span>{parseDate(template?.updatedAt)}</span>
    </div>
    <div>
      <span style={{ fontWeight: 'bold' }}>Created by:</span>
      &nbsp;
      <span>{template?.createdBy}</span>
    </div>
  </>
);

export default Details;
