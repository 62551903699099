import styled, { css } from 'styled-components';
import { Button } from 'components';

export const Container = styled.div`
  margin-top: 24px;
`;

export const AmountFound = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.hintMessage};
  margin-top: 24px;
`;

export const Status = styled.div<{ status: boolean }>`
  display: flex;
  align-items: center;
  white-space: nowrap;

  &:before {
    content: '';
    display: block;
    margin-right: 8px;
    min-width: 12px;
    height: 12px;
    background: ${(props) => (props.status ? props.theme.colors.success : props.theme.colors.warning)};
    border-radius: 50%;
  }
`;

export const FiltersContainer = styled.div`
  position: relative;
  margin-left: 24px;
  margin-right: 12px;

  & h3 {
    font-size: 18px;
    color: ${(props) => props.theme.colors.hintMessage};
    font-weight: 500;
  }
`;

export const FiltersButton = styled(Button)<{ isActive: boolean }>`
  position: relative;
  ${(props) => props.isActive && `border-color: ${props.theme.colors.primary}`};
`;

export const Divider = styled.div`
  margin: 20px 0;
  width: 100%;
  height: 1px;
  background: ${(props) => props.theme.colors.grayBorder};
`;

const smallAmount = css`
  padding: 0;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  width: 16px;
  height: 16px;
  position: absolute;
  right: -8px;
  bottom: -8px;
`;

export const Amount = styled.div<{ isSmall?: boolean; inactive?: boolean }>`
  background: ${(props) => (props.inactive ? props.theme.colors.inactiveBack : props.theme.colors.lightBlue)};
  border-radius: 24px;
  font-weight: 500;
  color: ${(props) => (props.inactive ? props.theme.colors.inactive : props.theme.colors.primary)};
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  padding: 0 10px;

  ${(props) => props.isSmall && smallAmount};
`;
