const theme = {
  colors: {
    white: '#fff',
    dark: '#202020',
    gray: '#eaeaea',
    lightGray: '#fafafa',
    darkGray: '#8E8E8E',
    grayBorder: '#DEDEDE',
    buttonBorder: '#cecece',
    primary: '#0045a5',
    lightBlue: '#EBF1F8',
    warning: '#DD2E44',
    inactive: '#979797',
    inactiveBack: '#efefef',
    hintMessage: '#303030',
    success: '#17855E',
    orange: '#F25800',
    textGray: '#595959',
    lightGrayBackground: '#FBFBFB',
    tableHeader: '#F5F5F5',
  },
  boxShadow: '0 1px 1px 0 rgba(0,0,0,0.12), 0 0px 4px 0 rgba(0,0,0,0.14), 0 2px 4px -1px rgba(0,0,0,0.2)',
};

export default theme;
