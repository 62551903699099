import React from 'react';
import styled from 'styled-components';
import { Flex } from 'components';
import { useAuth0 } from '@auth0/auth0-react';

import Logo from 'assets/UCLogo.svg';

const PageWrapper = styled(Flex)`
  width: 100vw;
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.lightGray};
`;

const StyledLogo = styled.img`
  width: 460px;
  margin-bottom: 35px;
`;

const LoginButton = styled.button`
  height: 40px;
  width: 85px;
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.primary};
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <PageWrapper flexDirection="column" justifyContent="center" alignItems="center">
      <StyledLogo src={Logo} alt="usercentrics" />
      <LoginButton onClick={() => loginWithRedirect()}>Login</LoginButton>
    </PageWrapper>
  );
};

export default Login;
