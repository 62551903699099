import React from 'react';
import styled from 'styled-components';
import { Dropdown as AntdDropdown } from 'antd';
import AngleIcon from 'assets/icons/Dropdown.svg';

type Item = {
  key: string;
  value: string;
};

interface IProps {
  items: Item[];
  value: string;
  onChange: (newValue: string) => void;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const SAntdDropdown = styled(AntdDropdown)`
  & .rc-virtual-list-scrollbar {
    display: block !important;
  }

  & .ant-dropdown-menu-item-selected {
    background-color: green !important;
  }
`;

const ActiveItem = styled.button`
  background: white;
  padding: 0 16px;
  border: 1px solid ${(props) => props.theme.colors.buttonBorder};
  height: 40px;
  color: ${(props) => props.theme.colors.hintMessage};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
`;

const Dropdown = ({ items, value, onChange, ...props }: IProps) => {
  const active = items.find((item) => item.key === value);

  return (
    <SAntdDropdown
      menu={{
        defaultSelectedKeys: [value],
        selectable: true,
        items: items.map((item) => ({ key: item.key, label: item.value })),
        onClick: (item) => onChange(item.key),
      }}
    >
      <ActiveItem {...props}>
        {active?.value}
        <img src={AngleIcon} alt="dropdown icon" />
      </ActiveItem>
    </SAntdDropdown>
  );
};

export default Dropdown;
