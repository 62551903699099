import styled from 'styled-components';
import { Checkbox as AntdCheckbox } from 'antd';

export const Checkbox = styled(AntdCheckbox)`
  & .ant-checkbox-inner {
    border-radius: 2px;
    border-color: ${(props) => props.theme.colors.buttonBorder};
  }

  &.ant-checkbox-wrapper-checked .ant-checkbox-inner {
    background-color: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};

    &:after {
      transform: rotate(45deg) scale(0.8) translate(-55%, -65%);
    }
  }

  &.ant-checkbox-wrapper-checked.ant-checkbox-wrapper:hover .ant-checkbox-inner {
    background-color: rgb(0 93 222) !important;
  }

  &.ant-checkbox-wrapper-disabled.ant-checkbox-wrapper-checked {
    opacity: 0.6;
  }
`;

export const CheckboxGroup = styled(AntdCheckbox.Group)`
  display: flex;
  flex-direction: column;

  & > label:not(:last-child) {
    margin-bottom: 4px;
  }

  & .ant-checkbox-inner {
    border-radius: 2px;
    border-color: ${(props) => props.theme.colors.buttonBorder};
  }

  & .ant-checkbox-wrapper-checked .ant-checkbox-inner {
    background-color: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};

    &:after {
      transform: rotate(45deg) scale(0.8) translate(-55%, -65%);
    }
  }

  & .ant-checkbox-wrapper-checked.ant-checkbox-wrapper:hover .ant-checkbox-inner {
    background-color: rgb(0 93 222) !important;
  }
`;
