import { gql } from '@apollo/client';

const GET_SUPER_ADMIN_DATA = gql`
  query superAdminData {
    superAdminData(app: consentTemplates) {
      email
      type
      appAccess
    }
  }
`;

export default GET_SUPER_ADMIN_DATA;
