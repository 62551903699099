import React, { createContext, ReactNode } from 'react';
import { notification } from 'antd';

type NotificationParams = {
  notificationMessage: string;
  type: 'error' | 'warning' | 'info' | 'success';
  action?: ReactNode;
};

interface IContextProps {
  notify: ({ notificationMessage, type, action }: NotificationParams) => void;
}

export const NotificationContext = createContext({} as IContextProps);

type ProviderProps = {
  children: ReactNode;
};

export default function NotificationProvider({ children, ...props }: ProviderProps) {
  const [api, contextHolder] = notification.useNotification();

  const notify = ({ notificationMessage, type, action }: NotificationParams) => {
    api[type]({
      message: notificationMessage,
      placement: 'bottomRight',
      description: action,
      duration: 100,
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        notify,
      }}
      {...props}
    >
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
}
