import React, { useState, useRef, useEffect } from 'react';
import { Category, ComponentType } from 'lib/Intefaces/IComponent';
import { useOnClickOutside, useKeyPress } from 'lib/hooks';
import { capitalize } from 'lib/helpers/strings';
import { Tooltip, Flex, Button, Label, CheckboxGroup } from 'components';
import FilterIcon from 'assets/icons/filters.svg';
import { FiltersContainer, FiltersButton, Divider, Amount } from './styled';

interface IProps {
  onChange: (categories: Category[], status: string[], types: ComponentType[]) => void;
}

const TYPES = Object.keys(ComponentType).map((key) => ({
  label: ComponentType[key as keyof typeof ComponentType],
  value: key,
}));
const CATEGORIES = Object.keys(Category).map((key) => ({
  label: capitalize(key),
  value: key,
}));
const STATUSES = [
  { label: 'Mapped', value: 'mapped' },
  { label: 'Unmapped', value: 'unmapped' },
];

const Filters = ({ onChange }: IProps) => {
  const tooltip = useRef(null);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>(
    CATEGORIES.map((c) => c.value) as Category[],
  );
  const [selectedTypes, setSelectedTypes] = useState<ComponentType[]>(TYPES.map((t) => t.value) as ComponentType[]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>(STATUSES.map((s) => s.value));

  const onClose = () => setShowFilters(false);

  useOnClickOutside(tooltip, onClose);
  useKeyPress('Escape', onClose);

  const onReset = () => {
    setSelectedTypes(TYPES.map((t) => t.value) as ComponentType[]);
    setSelectedCategories(CATEGORIES.map((c) => c.value) as Category[]);
    setSelectedStatus(STATUSES.map((s) => s.value));
  };

  const onApply = () => {
    onChange(selectedCategories, selectedStatus, selectedTypes);
    onClose();
  };

  useEffect(() => {
    onChange(selectedCategories, selectedStatus, selectedTypes);
  }, []);

  const filtersAmount = selectedCategories.length + selectedStatus.length + selectedTypes.length;

  return (
    <FiltersContainer ref={tooltip}>
      <FiltersButton isActive={showFilters} onClick={() => setShowFilters(!showFilters)} styleType="white">
        <img src={FilterIcon} alt="filters icon" width="14px" />
        {!showFilters && Boolean(filtersAmount) && <Amount isSmall>{filtersAmount}</Amount>}
      </FiltersButton>
      {showFilters && (
        <Tooltip style={{ width: '280px' }}>
          <Flex justifyContent="space-between" alignItems="center">
            <h3>Filter</h3>
            <Amount inactive={!filtersAmount}>{filtersAmount}</Amount>
          </Flex>
          <Divider />
          <Label>Categories</Label>
          <CheckboxGroup
            options={CATEGORIES}
            defaultValue={CATEGORIES.map((c) => c.value)}
            onChange={(values) => setSelectedCategories(values as Category[])}
            value={selectedCategories}
          />
          <Label style={{ marginTop: '20px' }}>Status</Label>
          <CheckboxGroup
            options={STATUSES}
            defaultValue={STATUSES.map((c) => c.value)}
            onChange={(values) => setSelectedStatus(values as string[])}
            value={selectedStatus}
          />
          <Label style={{ marginTop: '20px' }}>Type</Label>
          <CheckboxGroup
            options={TYPES}
            defaultValue={TYPES.map((c) => c.value)}
            onChange={(values) => setSelectedTypes(values as ComponentType[])}
            value={selectedTypes}
          />
          <Divider />
          <Flex justifyContent="space-between" alignItems="center">
            <Button size="small" styleType="secondary" onClick={onReset} style={{ width: 'calc(50% - 5px)' }}>
              Reset
            </Button>
            <Button size="small" onClick={onApply} style={{ width: 'calc(50% - 5px)' }}>
              Apply
            </Button>
          </Flex>
        </Tooltip>
      )}
    </FiltersContainer>
  );
};

export default Filters;
