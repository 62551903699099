import React, { useEffect } from 'react';
import { Form, FormInstance } from 'antd';
import { Input, ErrorMessage, Item, Flex, TextArea, Select, Option, Collapse, Label, Button } from 'components';
import { useAccess } from 'lib/hooks';
import { IComponent, Category, ComponentType } from 'lib/Intefaces/IComponent';
import { deepMerge } from 'lib/helpers/objects';

interface IProps {
  onChange: (value: any) => void;
  component: IComponent;
  form: FormInstance<any>;
  errors: {
    uniqueRegexes: boolean;
  };
}

const PrimaryComponent = ({ errors, form, component, onChange }: IProps) => {
  const { isReadAccess } = useAccess();

  const onFieldsChange = () => onChange(deepMerge(component, form.getFieldsValue()));

  useEffect(() => {
    form.resetFields();
  }, [component.bundleId]);

  return (
    <Form form={form} layout="vertical" onValuesChange={onFieldsChange} initialValues={component}>
      <Item
        label="Component Name"
        colon={false}
        name="name"
        rules={[{ required: true, message: 'Component name should not be empty' }]}
      >
        <Input placeholder="Enter component name" disabled={isReadAccess} />
      </Item>
      <Flex justifyContent="space-between">
        <Item
          label="Category"
          colon={false}
          name="category"
          style={{ width: 'calc(50% - 12px)' }}
          rules={[{ required: true, message: 'Select a category' }]}
        >
          <Select placeholder="Select category" size="large" disabled={isReadAccess}>
            {(Object.keys(Category) as Array<keyof typeof Category>).map((c) => (
              <Option key={c} value={c}>
                {Category[c]}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          label="TemplateID"
          colon={false}
          name="templateId"
          style={{ width: 'calc(50% - 12px)' }}
          rules={[{ required: true, message: 'TemplateID should not be empty' }]}
        >
          <Input placeholder="TemplateID" disabled={isReadAccess} />
        </Item>
      </Flex>
      <Flex justifyContent="space-between">
        <Item
          label="Component Type"
          colon={false}
          name="componentType"
          style={{ width: 'calc(50% - 12px)' }}
          rules={[{ required: true, message: 'Select a type' }]}
        >
          <Select placeholder="Select type" size="large" disabled={isReadAccess}>
            {(Object.keys(ComponentType) as Array<keyof typeof ComponentType>).map((t) => (
              <Option key={t} value={t}>
                {ComponentType[t]}
              </Option>
            ))}
          </Select>
        </Item>
        <Item label="Component Link" colon={false} name="componentLink" style={{ width: 'calc(50% - 12px)' }}>
          <Input placeholder="Component Link" disabled={isReadAccess} />
        </Item>
      </Flex>
      <Item
        label="Description"
        colon={false}
        name="description"
        rules={[{ required: true, message: 'Description should not be empty' }]}
      >
        <TextArea placeholder="Description" style={{ minHeight: '60px', maxHeight: '100px' }} disabled={isReadAccess} />
      </Item>
      <Item label="CanaryURL" colon={false} name="canaryUrl">
        <Input placeholder="CanaryURL" disabled={isReadAccess} />
      </Item>
      <Item label="ManifestName" colon={false} name="manifestName">
        <Input placeholder="ManifestName" disabled={isReadAccess} />
      </Item>
      <Label>Unique Regex(s)</Label>
      {errors.uniqueRegexes && <ErrorMessage>Add at least one unique regex.</ErrorMessage>}
      <Form.List name="uniqueRegexes">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Collapse
                key={field.key}
                title={form.getFieldValue(['uniqueRegexes', field.name, 'regex']) || 'Regex ' + (field.key + 1)}
                onDelete={() => remove(field.name)}
                style={{ marginBottom: '8px' }}
              >
                <Item
                  label="Regex"
                  colon={false}
                  name={[field.name, 'regex']}
                  rules={[{ required: true, message: 'Regex should not be empty' }]}
                >
                  <Input placeholder="Regex" disabled={isReadAccess} />
                </Item>
                <Item label="Example" colon={false} name={[field.name, 'example']}>
                  <Input placeholder="Example" disabled={isReadAccess} />
                </Item>
              </Collapse>
            ))}
            {!isReadAccess && (
              <Button
                styleType="secondary"
                size="small"
                onClick={() => add({ regex: '', example: '' })}
                style={{ marginTop: '16px' }}
              >
                Add Regex
              </Button>
            )}
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default PrimaryComponent;
