import React from 'react';

import { StyledTable } from './styled';

interface IColumn {
  title: (() => JSX.Element) | string;
  dataIndex?: string;
  sorter?: (a: any, b: any) => number;
  width?: string;
  key?: string;
  render?: (value: any, record: any, index: number) => JSX.Element;
}

interface ITableProps<T> {
  columns: IColumn[];
  data: T[];
  noBorder?: boolean;
  autoHeight?: boolean;
  style?: React.CSSProperties;
  pagination?: boolean;
  loading?: boolean;
  onRow?: (record: any) => {
    onClick: (event: React.MouseEvent<HTMLTableRowElement>) => void;
  };
  rowSelection?: {
    onChange?: (selectedRowKeys: React.Key[], selectedRows: any[]) => void;
    selectedRowKeys?: React.Key[];
  };
  scroll?: any;
}

const Table = <T extends {}>({
  columns,
  data,
  onRow,
  autoHeight = false,
  noBorder = false,
  pagination = false,
  loading = false,
  rowSelection,
  style,
  ...props
}: ITableProps<T>) => (
  <StyledTable
    columns={columns}
    dataSource={data}
    pagination={pagination ? { position: ['bottomRight'], pageSize: 50, showSizeChanger: false } : false}
    onRow={onRow}
    style={style}
    loading={loading}
    autoHeight={autoHeight}
    noBorder={noBorder}
    rowSelection={rowSelection}
    isClickable={Boolean(onRow)}
    {...props}
  />
);

export default Table;
