import React, { useState } from 'react';
import {
  dataPurposes,
  dataTransferCountries,
  legalBasis,
  technologyUsed,
} from '@usercentrics/template-app-translations';
import defaultCategorySlug from 'lib/predefinedValues/defaultCategorySlug.json';
import { SSearchInput, SDropdown, STagSelect } from './styled';

export enum SearchFilters {
  NAME_OR_ID = 'nameOrId',
  PROCESSING_COMPANY = 'nameOfProcessingCompany',
  PRIVACY_POLICY_URL = 'privacyPolicyURL',
  COOKIE_POLICY_URL = 'cookiePolicyURL',
  DATA_RECIPIENTS_LIST = 'dataRecipientsList',
  DATA_PURPOSES_LIST = 'dataPurposesList',
  THIRD_COUNTRY_TRANSFER = 'thirdCountryTransfer',
  TECHNOLOGY_USED = 'technologyUsed',
  LEGAL_BASIS = 'legalBasisList',
  OPTOUT_URL = 'optOutUrl',
  DEFAULT_CATEGORY_SLUG = 'defaultCategorySlug',
  STORAGE_DETAILS = 'deviceStorageDisclosuresName',
}

const CATEGORIES = { en: defaultCategorySlug.reduce((acc, category) => ({ ...acc, [category.en]: category.en }), {}) };

const FILTERS: { [key in SearchFilters]: { label: string; type: 'text' | 'tags'; items?: any } } = {
  [SearchFilters.NAME_OR_ID]: { label: 'Template name / ID', type: 'text' },
  [SearchFilters.PROCESSING_COMPANY]: { label: 'Processing Company', type: 'text' },
  [SearchFilters.PRIVACY_POLICY_URL]: { label: 'Privacy Policy Link', type: 'text' },
  [SearchFilters.COOKIE_POLICY_URL]: { label: 'Cookie Policy Link', type: 'text' },
  [SearchFilters.DATA_RECIPIENTS_LIST]: { label: 'Data Recipients', type: 'text' },
  [SearchFilters.DATA_PURPOSES_LIST]: { label: 'Data purpose', type: 'tags', items: dataPurposes },
  [SearchFilters.THIRD_COUNTRY_TRANSFER]: {
    label: 'Data Transfer to Third Countries',
    type: 'tags',
    items: dataTransferCountries,
  },
  [SearchFilters.TECHNOLOGY_USED]: { label: 'Technologies Used', type: 'tags', items: technologyUsed },
  [SearchFilters.LEGAL_BASIS]: { label: 'Legal basis', type: 'tags', items: legalBasis },
  [SearchFilters.OPTOUT_URL]: { label: 'Opt-out Link', type: 'text' },
  [SearchFilters.DEFAULT_CATEGORY_SLUG]: { label: 'Recommended Category', type: 'tags', items: CATEGORIES },
  [SearchFilters.STORAGE_DETAILS]: { label: 'Storage Details', type: 'text' },
};

interface IProps {
  onSearch: (field: SearchFilters, value: string) => void;
  searching: boolean;
}

const SearchInputContainer = ({ onSearch, searching }: IProps) => {
  const [value, setValue] = useState<string>('');
  const [filter, setFilter] = useState<SearchFilters>(SearchFilters.NAME_OR_ID);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const onTagsSelect = (tags: any) => {
    setSelectedTags(tags);
    onSearch(filter, tags);
  };

  return (
    <>
      <SDropdown
        items={Object.keys(FILTERS).map((key) => ({ key, value: FILTERS[key as SearchFilters].label }))}
        onChange={(f) => {
          setSelectedTags([]);
          setFilter(f as SearchFilters);
          onSearch(f as SearchFilters, value);
        }}
        value={filter}
      />
      {FILTERS[filter].type === 'text' ? (
        <SSearchInput
          value={value}
          onChange={setValue}
          onSearch={(v) => {
            setValue(v);
            onSearch(filter, v);
          }}
          loading={searching}
          placeholder="Search for component"
          style={{ flex: 1, marginRight: '24px' }}
        />
      ) : (
        <STagSelect
          mode="multiple"
          placeholder="Please select"
          defaultValue={[]}
          allowClear
          value={selectedTags}
          onChange={onTagsSelect}
          options={Object.keys(FILTERS[filter].items.en).map((key) => ({ value: key, label: key }))}
          style={{ flex: 1, marginRight: '24px' }}
        />
      )}
    </>
  );
};

export default SearchInputContainer;
