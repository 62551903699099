import { gql } from '@apollo/client';
import { LANGUAGES } from '@usercentrics/template-app-translations';

const GET_TRANSLATIONS = gql`
  query getTranslation(
    $translationFor: String!
    $sourceLanguage: String!
    $targetLanguages: [String]
  ) {
    getTranslation(
      translationFor: $translationFor
      sourceLanguage: $sourceLanguage
      targetLanguages: $targetLanguages
    ) {
      ${LANGUAGES.join('\n')}
    }
  }
`;

export default GET_TRANSLATIONS;
